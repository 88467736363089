import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../HOC/Axios/Axios";
import * as yup from "yup";
import image from "../../Resources/Images/dummyImage.svg";
// import Spinner from "../../components/pageComponents/Spinner/Spinner";
import Loader from "../../components/pageComponents/Spinner/loader";
import ScrollOff from "../../components/pageComponents/WindowScrollOff/ScrollOff";

function WalletSetting() {
  const [Images, setImages] = useState("");
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);

  const walletSettingSchema = yup.object().shape({
    walletAddress: yup.string().required("Wallet Address is required"),
  });

  const getData = async () => {
    setSpinner(true);

    try {
      const response = await axios.get("/company/getWalletInfo");
      console.log(response);
      if (response.status === 200 && response.data) {
        setData(response.data);
      }
      setSpinner(false);
    } catch (error) {
      console.error(error);
      setSpinner(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    // console.log(Images);
    // console.log(data[0]?.depositQr);
    if (Images) {
      formData.append("depositQr", Images);
    } else if (data?.length > 0 && data[0]?.depositQr) {
      // If no new image is selected, use the existing depositQr or image value from data
      formData.append("depositQr", data[0].depositQr);
    }

    formData.append("walletAddress", values.walletAddress);
    setSpinner(true);
    try {
      const response = await axios.post("/company/postWalletInfo", formData);
      console.log(response);
      if (response.status === 201) {
        toast.success(response.data.message, {
          toastId: "Added success",
        });
        setSpinner(false);
        setImages("");
        // After successful submission, fetch the data again to update the form
        getData();
        resetForm();
      }
    } catch (error) {
      console.error(error);
      document.body.style.overflow = "auto";
      toast.error(error?.response?.data?.message);
      setSpinner(false);
    }
  };

  return (
    <div className="w-6/12 md:w-8/12 md:grid grid-cols-2 gap-10  py-5 mx-ato px-10 text-center">
      <ScrollOff message={spinner}> {spinner && <Loader />}</ScrollOff>
      <div className="w-full">
        <ToastContainer autoClose={1000} />
        <Formik
          initialValues={{
            walletAddress: data?.length > 0 ? data[0]?.walletAddress : "",
          }}
          onSubmit={handleSubmit}
          validationSchema={walletSettingSchema}
          enableReinitialize // This enables reinitialization when initialValues change
        >
          {({ errors, touched }) => (
            <Form className="rounded-[5px] text-left">
              <div className="mb-2 text-left">
                <label htmlFor="image" className="capitalize font-semibold">
                  Upload QR Image
                </label>
              </div>
              <div>
                <input
                  id="image"
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => setImages(e.target.files[0])}
                  className="hidden"
                />
                <div className="border w-full overflow-hidden p-1 border-gray-200 border-dotted">
                  {Images ? (
                    <div className="h-[300px] bg-white opacity-90 flex justify-center items-center">
                      <img
                        src={URL.createObjectURL(Images)}
                        alt="QR Code"
                        width={100}
                        height={100}
                        className="object-contain h-[300px] w-full object-center"
                      />
                    </div>
                  ) : (
                    <div className="h-[300px] bg-gray-800 opacity-90 flex justify-center items-center">
                      <img
                        src={data[0]?.depositQr || image}
                        alt="Default QR Code"
                        width={100}
                        height={100}
                        className="object-contain h-[200px] opacity-100 w-full object-top"
                      />
                    </div>
                  )}
                </div>
                <label htmlFor="image">
                  <div className="bg-secondMainColor capitalize hover:bg-opacity-80 cursor-pointer text-center text-white px-4 py-2">
                    Update QR Image
                  </div>
                </label>
                <div className="flex flex-col gap-1 mt-5">
                  <label
                    htmlFor="walletAddress"
                    className="capitalize mb-2 font-semibold text-left"
                  >
                    Wallet Address
                  </label>
                  <Field
                    id="walletAddress"
                    name="walletAddress"
                    type="text"
                    placeholder="Wallet Address"
                    className="p-4 rounded-[3px] border border-gray-400 bg-white text-sm placeholder:text-xs sm:placeholder:text-sm focus:outline-primary"
                  />
                  {errors.walletAddress && touched.walletAddress && (
                    <div className="text-red-500 text-sm mt-1 capitalize px-2">
                      {errors.walletAddress}
                    </div>
                  )}
                </div>
                <div className="mt-6">
                  <button
                    type="submit"
                    className={`px-[30px] mt-6 py-[10px] bg-[#833CDC] capitalize flex items-center gap-1 rounded-md text-white`}
                  >
                    {data.length > 0 ? "Upload" : "Submit"}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default WalletSetting;
