import React, { useState } from "react";
import AddAmount from "./UserAmount/AddAmount";
import SubtractAmount from "./UserAmount/SubtractAmount";
import { IoClose } from "react-icons/io5";
import ScrollOff from "../WindowScrollOff/ScrollOff";

function UserUpdateAmount({
  setShowUpdateAmount,
  userId,
  getUserData,
  message,
  setMessage,
  SubmitWithAmountDisplay,
  storeAcceptID,
  setStoreAcceptID,
  type,
  title,
}) {
  const [storeValue, setStoreValue] = useState("add");

  const data = [
    {
      title: "credit amount to user ",
      type: "radio",
      apikey: "add",
      value: "add",
    },
    {
      title: "debit amount from user",
      type: "radio",
      apikey: "sub",
      value: "sub",
    },
  ];
  const handleDataClick = () => {
    let mod;
    switch (storeValue) {
      case "add":
        mod = (
          <AddAmount
            title={title}
            userId={userId}
            getUserData={getUserData}
            SubmitWithAmountDisplay={SubmitWithAmountDisplay}
            setMessage={setMessage}
            storeAcceptID={storeAcceptID}
            setStoreAcceptID={setStoreAcceptID}
            type={type}
            setShowUpdateAmount={setShowUpdateAmount}
          />
        );
        break;
      case "sub":
        mod = (
          <SubtractAmount
            userId={userId}
            title={title}
            getUserData={getUserData}
            SubmitWithAmountDisplay={SubmitWithAmountDisplay}
            setMessage={setMessage}
            storeAcceptID={storeAcceptID}
            type={type}
            setShowUpdateAmount={setShowUpdateAmount}
          />
        );
        break;
      default:
        break;
    }
    return mod;
  };
  return (
    <ScrollOff message={message}>
      <div className="px-4 absolute    left-[30%]  top-[30%] z-50 h-fit w-6/12  py-6   bg-[white] ">
        <div
          className="text-xl cursor-pointer p-1.5 border
         text-white bg-red-600 rounded-full w-fit ml-auto "
          onClick={() => setShowUpdateAmount(false)}
        >
          <IoClose />
        </div>
        <div className=" flex px-8 gap-4 pb-5">
          {data.map((btn, index) => {
            return (
              <div key={index} className="flex gap-2   font-semibold uppercase">
                <div
                  className={`w-6 h-6 rounded-full cursor-pointer  ${
                    storeValue === btn.title
                      ? "border-2 border-[#833cdc]"
                      : null
                  } flex items-center justify-center  `}
                  onClick={() => setStoreValue(btn.apikey)}
                >
                  <div
                    className={`w-3 h-3 rounded-full ${
                      storeValue === btn.apikey
                        ? "  bg-[#833cdc]  "
                        : "bg-gray-400"
                    }`}
                  ></div>
                  {/* <input
                  type={btn.type}
                  name={btn.apikey}
                  value={btn.value}
                  checked={storeValue === btn.value}
                  onChange={(e) => setStoreValue(e.target.value)}
                  className={`text-blue-500 w-5 h-5 focus:ring-blue-500 ${
                    storeValue === btn.value ? "checked-radio  " : ""
                  }`}
                /> */}
                </div>
                <div className="">{btn.title}</div>
              </div>
            );
          })}
        </div>
        {/* {console.log(storeValue)} */}
        <div className="">{handleDataClick()}</div>
        {/* <AddAmount /> */}
      </div>
    </ScrollOff>
  );
}

export default UserUpdateAmount;

// import React, { useState } from "react";
// import { Formik, Form, Field } from "formik";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// // import axios from "../../api/axios";
// import * as yup from "yup";
// // import img from "../../../Resources/Logo.png";
// import Spinner from "../../../components/pageComponents/Spinner/Spinner";
// // import { useNavigate } from "react-router-dom";
// import { IoClose } from "react-icons/io5";
// function UserUpdateAmount({ setShowUpdateAmount }) {
//   const [Images, setImages] = useState("");
//   //   const [storeTargatedValue, setStoreTargatedValue] = useState([]);
//   const [showSpinner] = useState(false);
//   //   const navigate = useNavigate();
//   const buyTime = [
//     { id: 1, option: "1" },
//     { id: 2, option: "2" },
//     { id: 3, option: "3" },
//   ];
//   let buyTimeOpt = [];
//   buyTime.map((item) => {
//     return buyTimeOpt.push(item);
//   });

//   const buyForm = [
//     {
//       label: "full name",
//       type: "text",
//       apikey: "fullName",
//       placeholder: "full name",
//     },
//     {
//       label: "email",
//       type: "text",
//       apikey: "email",
//       placeholder: "email",
//     },
//     {
//       label: "password",
//       type: "text",
//       apikey: "password",
//       placeholder: "password",
//     },
//     {
//       label: "country",
//       type: "text",
//       apikey: "country",
//       placeholder: "country",
//     },
//     {
//       label: "phoneNo",
//       type: "number",
//       apikey: "phoneNo",
//       placeholder: "phoneNo",
//     },

//     {
//       label: "address",
//       type: "text",
//       apikey: "address",
//       placeholder: "address",
//     },
//     {
//       label: "credit score",
//       type: "text",
//       apikey: "creditScore",
//       placeholder: "credit score",
//     },
//   ];
//   const images = [
//     {
//       id: 0,
//       label: "legal document",
//       type: "file",
//       apikey: "validId",
//     },
//     {
//       id: 1,
//       label: "profile image",
//       type: "file",
//       apikey: "profile",
//     },
//   ];
//   const buySchema = yup.object().shape({
//     slogan: yup.string().required("required"),
//     description: yup.string().required("required"),
//     // partners: yup.string().required("required"),
//   });

//   // console.log(targetedValue);

//   let loader;
//   if (showSpinner) {
//     loader = (
//       <div className="fixed top-0 left-0 bg-black bg-opacity-50 w-full h-screen z-[900]">
//         <Spinner />
//       </div>
//     );
//   }

//   const handleImageChange = (e, label) => {
//     const newImages = { ...Images };
//     newImages[label] = e.target.files[0];
//     setImages(newImages);
//   };

//   const handleRemoveImage = (label) => {
//     const newImages = { ...Images };
//     delete newImages[label];
//     setImages(newImages);
//   };
//   return (
//     <div className="bg-white h-full">
//       {loader}
//       <div className="w-full text- [#303132]  relative">
//         <Formik
//           initialValues={{
//             fullName: "",
//             email: "",
//             password: "",
//             creditScore: "",
//             address: "",
//             country: "",
//             phoneNo: "",

//             // image: null,
//           }}
//           onSubmit={async (values, { resetForm }) => {
//             values.image = Images;
//             // let storeValue = [];
//             // storeTargatedValue?.map((val, i) => {
//             //   return storeValue.push(val);
//             // });
//             // values.partners = storeValue;
//             console.log(values);
//             // values.storeSellData

//             // try {
//             //   setIsLoading(true);
//             //   axios
//             //     .post("/buy", values)
//             //     .then((res) => {
//             //       console.log(res);
//             //       if (res.status === 200) {
//             //         toast.success("Data has been sent sucessfully");
//             //         setShow(false);
//             //         setIsLoading(false);
//             //         resetForm();
//             //       }
//             //     })
//             //     .catch((err) => {
//             //       console.log(err);
//             //       setIsLoading(false);
//             //     });
//             // } catch (error) {
//             //   console.log(error);
//             // }
//           }}
//           validationSchema={buySchema}
//         >
//           {({ handleSubmit, errors, touched, setFieldValue }) => {
//             return (
//               <Form className="  w-[95%]   rounded-[5px] mx-auto">
//                 <ToastContainer autoClose={1000} />
//                 <div className="text-base font-medium   text-[#686868] ">
//                   <button
//                     className="bg-GrayLight border  mt-4 w-fit px-4 py-1.5 rounded-sm  capitalize "
//                     onClick={() => setShowUpdateAmount(false)}
//                   >
//                     back
//                   </button>
//                 </div>
//                 <div className="  gap-7 laptop:gap-x-7 mt-5 laptop:gap-y-10 grid laptop:grid-cols-2  laptop:py-3 pb-7 laptop:pb-10 ">
//                   {buyForm.map((item, index) => {
//                     if (item.type === "select") {
//                       return (
//                         <div className="    relative " key={index}>
//                           <div className="absolute  -top-3 left-3  px-2 ">
//                             <label className="font-medium text-[13px]  mx-auto  px-3  text-[#9b9b9b] bg-[white]  capitalize">
//                               {item.label}
//                             </label>
//                           </div>
//                           <div
//                             className=" border border-[#c8c8c8]  "
//                             key={index}
//                           >
//                             <Field
//                               className="h-14   outline-none  px-5 bg-[white] w-full
//                                 text-sm placeholder:text-xs sm:placeholder:text-sm focus:outline-primary"
//                               as={item.type}
//                               name={item.apikey}
//                               placeholder={item.placeholder}
//                             >
//                               {" "}
//                               {item.options?.map((opt, i) => {
//                                 return (
//                                   <option
//                                     id={opt.apikey}
//                                     value={opt.apikey}
//                                     key={i}
//                                   >
//                                     {opt.option}
//                                   </option>
//                                 );
//                               })}
//                             </Field>
//                           </div>

//                           {errors[item.apikey] && touched[item.apikey] ? (
//                             <div className="text-red-500 text-sm mt-1 capitalize px-2">
//                               {errors[item.apikey]}
//                             </div>
//                           ) : (
//                             ""
//                           )}
//                         </div>
//                       );
//                     } else {
//                       return (
//                         <div className="   relative " key={index}>
//                           <div className="absolute  -top-3 left-3  px-2 ">
//                             <label className="font-medium text-[13px] bg-[white] mx-auto  px-3  text-[#9b9b9b]   capitalize">
//                               {item.label}
//                             </label>
//                           </div>
//                           <div
//                             className=" border border-[#ababab] "
//                             key={index}
//                           >
//                             <Field
//                               className="h-14 bg-[white]  outline-none  px-5  w-full
//                                   text-sm placeholder:text-xs sm:placeholder:text-sm focus:outline-primary"
//                               type={item.type}
//                               name={item.apikey}
//                               min={item.min ? item.min : null}
//                               placeholder={item.placeholder}
//                             />
//                           </div>

//                           {errors[item.apikey] && touched[item.apikey] ? (
//                             <div className="text-red-500 text-sm mt-1 capitalize px-2">
//                               {errors[item.apikey]}
//                             </div>
//                           ) : (
//                             ""
//                           )}
//                         </div>
//                       );
//                     }
//                   })}
//                 </div>

//                 <div className="flex flex-col mt-8 items-end  relative">
//                   {/* {Images ? ( */}
//                   <button
//                     className={`px-[47px] py-[10px] bg-[#833CDC] flex items-center gap-1 rounded-md text-white ${
//                       Images ? "" : "cursor-not-allowed"
//                     }`}
//                     type="submit"
//                     disabled={Images ? false : true}
//                   >
//                     Submit
//                   </button>
//                 </div>
//                 {/* <div className=" w-fit "></div> */}
//               </Form>
//             );
//           }}
//         </Formik>
//       </div>
//     </div>
//   );
// }

// export default UserUpdateAmount;
