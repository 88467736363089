import * as yup from "yup";
// const phoneRgx = RegExp(
//   /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
// );
// const DataEmpty = RegExp(/[]+/);
const PasswordRgxCapitaCharacter = RegExp(/[A-Z]+/);
const PasswordRgx = RegExp(/[a-z]+/);
// const PasswordRgxSpaces = RegExp(/\s/);
// const PasswordRgxNumber = RegExp(/[0-9]+/);
// const PasswordRgxSpecialLast = RegExp(/["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/);
const PasswordRgxSpecial = RegExp(/[" !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/);
// const oldPasswordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{7,}$/;

export const buySchema = yup.object().shape({
  fullName: yup
    .string()
    .required("Name is required")
    .matches(/^[a-zA-Z0-9\s]+$/, "Only alphabets, numbers, and spaces allowed"),
  email: yup.string().email("Invalid email").required("required"),
  // password: yup.string().required("required"),
  country: yup
    .string()
    .required("country name is required")
    .matches(/^[a-zA-Z\s]+$/, "Only alphabets, and spaces allowed"),
  phoneNo: yup
    .string()
    .min(10, "too small")
    // .max(1, "too large")
    .required("Only number is  allowed ")
    .test("special", "only number is  allowed ", (value) => {
      if (PasswordRgxSpecial.test(value)) {
        return false;
      } else {
        return true;
      }
    })
    .test("number", "only number is  allowed ", (value) => {
      if (PasswordRgxCapitaCharacter.test(value)) {
        return false;
      } else {
        return value;
      }
    })
    .test("number", "only number is  allowed ", (value) => {
      if (PasswordRgx.test(value)) {
        return false;
      } else {
        return value;
      }
    }),
  address: yup
    .string()
    .required("Address name is required")
    .matches(
      /^[a-zA-Z_,0-9 -\s]+$/,
      "Only alphabets , _ numbers and spaces are  allowed"
    ),
  creditScore: yup
    .string()
    .required("Credit score  is required")
    .matches(/^[0-9]+$/, "Only numbers and spaces are  allowed"),
});

export const AdminIdCreateSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[A-Z])/,
      "Password must contain at least one uppercase letter"
    )
    .matches(
      /^(?=.*[!@#$%^&*])/,
      "Password must contain at least one special character"
    )
    .matches(/^(?=.*[0-9])/, "Password must contain at least one number")
    .min(7, "Password must be at least 7 characters long"),
  // fullName: yup
  //   .string()
  //   .required("Name is required")
  //   .matches(/^[a-zA-Z0-9\s]+$/, "Only alphabets, numbers, and spaces allowed"),
  // phoneNo: yup
  //   .string()
  //   .min(10, "Phone number must be at least 10 digits")
  //   .max(10, "Phone number cannot be more than 10 digits")
  //   .required("Only number is  allowed ")
  //   .test("special", "only number is  allowed ", (value) => {
  //     if (PasswordRgxSpecial.test(value)) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   })
  //   .test("number", "only number is  allowed ", (value) => {
  //     if (PasswordRgxCapitaCharacter.test(value)) {
  //       return false;
  //     } else {
  //       return value;
  //     }
  //   })
  //   .test("number", "only number is  allowed ", (value) => {
  //     if (PasswordRgx.test(value)) {
  //       return false;
  //     } else {
  //       return value;
  //     }
  //   }),
});
export const forgotPasswordSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Required"),
  oldPassword: yup.string().required("Required"),
  //   .matches(
  //     oldPasswordRegex,
  //     // "Password must contain at least one uppercase letter, one special character, one number, and be at least 7 characters long"
  //   ),
  newPassword: yup
    .string()
    .required("Required")
    .matches(
      /^(?=.*[A-Z])/,
      "Password must contain at least one uppercase letter"
    )
    .matches(
      /^(?=.*[!@#$%^&*])/,
      "Password must contain at least one special character"
    )
    .matches(/^(?=.*[0-9])/, "Password must contain at least one number")
    .min(7, "Password must be at least 7 characters long")
    .notOneOf(
      [yup.ref("oldPassword")],
      "New password must be different from old password"
    ),
  confirmNewPassword: yup
    .string()
    .required("Required")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});
