import React from "react";

function Collapse({
  children,
  title,
  showSidebar,
  setShow,
  isOpen,
  setShowCancelButton,
}) {
  return (
    <div
      onClick={() => {
        setShow(false);
        setShowCancelButton(false);
        // document.body.style.overflow = "hidden";
      }}
      className={`overflow-hidden
      
      ${
        isOpen && title === "report"
          ? "h-[182px]"
          : isOpen && title !== "dashboard"
          ? "h-32"
          : "h-0"
      }
     ${
       showSidebar ? "w-fit" : "w-[95%] "
     } mx-auto transition-all ease-in-out delay-100 duration-500 bg-[#f3f4f4] `}
    >
      {children}
    </div>
  );
}

export default Collapse;
