import React, { useState, useEffect } from "react";
import Table from "../../UI/Table";
import TableFilter from "../../components/pageComponents/TableFilter/TableFilter";
import ContextApi from "../../HOC/ContextApi/ContextApi";
import Loader from "../../components/pageComponents/Spinner/loader";
import axios from "../../HOC/Axios/Axios";
function TransactionReport() {
  const [tableBody, setTableBody] = useState([]);
  const [counter, setCounter] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [filteredData, setFilteredData] = useState(false);
  const [pagination] = useState({
    start: 0,
    end: 10,
  });

  const EndValue = counter * pagination.end;
  const StartValue = EndValue - pagination.end;

  const Thead = [
    { title: "s.nO", with: "20%" },
    { title: "bankName", with: "20%" },
    { title: "date", with: "20%" },
    { title: "accountHolderName", with: "20%" },
    { title: "accountNO", with: "20%" },
    { title: "amount", with: "20%" },
    { title: "status", with: "20%" },
  ];

  const HandleSearchInputChange = (e) => {
    // console.log(e.target.value);
    setFilteredData(e.target.value);
  };

  const Filter = (options) => {
    return options.filter((option, index) => {
      const status = option["status"] || "";
      const accountHolderName = option["accountHolderName"] || "";
      const searchData = filteredData || "";
      return (
        status.toLowerCase().includes(searchData.toLowerCase()) ||
        accountHolderName.toLowerCase().includes(searchData.toLowerCase())
      );
    });
  };
  const getTradeData = async () => {
    setShowLoader(true);
    try {
      const deopsitBank = await axios.get("/deposit");
      const depositUSDT = await axios.get("/deposit/getQrDeposit");
      const withdrawBank = await axios.get("/withdrawal");
      const withdrawUSDT = await axios.get("/withdrawal/GetAllUSDTdata");
      const mergedData = [
        ...deopsitBank.data,
        ...depositUSDT.data.data,
        ...withdrawBank.data.data,
        ...withdrawUSDT.data.data,
      ];
      setTableBody(mergedData);
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getTradeData();
  }, []);

  const NotpendingData = tableBody.filter((val, i) => {
    return val.status !== "pending";
  });

  return (
    <ContextApi>
      {showLoader && <Loader loader={showLoader} />}
      <TableFilter
        HandleSearchInputChange={HandleSearchInputChange}
        Filter={Filter}
        placeholder={"Search by status or account holder name..."}
      />
      {NotpendingData?.length > 0 ? (
        <div className="overflow-x-auto">
          {Filter(NotpendingData).length > 0 ? (
            <Table
              Head={Thead}
              counter={counter}
              setCounter={setCounter}
              data={NotpendingData}
              paginationEnd={pagination.end}
            >
              {Filter(NotpendingData)
                // .filter((val) => val.tradestatus === "close")
                .slice(StartValue, EndValue)
                .map((val, i) => {
                  return (
                    <tr
                      key={i}
                      width={val.width}
                      className="border-b    hover:bg-white text-center"
                    >
                      <td className="px-3 py-7 ">{StartValue + 1 + i}</td>
                      <td className="px-3 text-sm capitalize">
                        {val.bankName
                          ? val.bankName
                          : val.walletAddress
                          ? "withdraw through USDT"
                          : "deposit through USDT"}
                      </td>
                      <td className="px-3 text-sm">
                        {new Date(val.createdAt).toDateString()}
                      </td>

                      <td className="px-3 font-semibold capitalize">
                        {val.accountHolderName ? (
                          val.accountHolderName
                        ) : val.accountHolderName ? (
                          val.accountHolderName
                        ) : (
                          <span className="text-gray-400 text-sm">N/A</span>
                        )}
                      </td>
                      <td className="px-3 text-sm">
                        {val.accountNo ? (
                          val.accountNo
                        ) : val.accountNO ? (
                          val.accountNO
                        ) : (
                          <span className="text-gray-400 font-medium">N/A</span>
                        )}
                      </td>
                      <td className="px-1 text-sm">
                        {val.amount ? (
                          ` $ ${val.amount}`
                        ) : val.withdrawalAmount ? (
                          ` $ ${val.withdrawalAmount}`
                        ) : (
                          <span className="text-gray-400 font-medium">N/A</span>
                        )}
                      </td>
                      <td
                        className={`px-3 flex items-center justify-center  capitalize mt-5  
                      ${
                        val?.status === "rejected"
                          ? "text-red-500"
                          : val?.status === "completed"
                          ? "text-green-500"
                          : val?.status === "settled"
                          ? "text-blue-500"
                          : ""
                      }
                      `}
                      >
                        <div className={``}>{val?.status} </div>
                      </td>
                    </tr>
                  );
                })}
            </Table>
          ) : (
            <div className="text-left px-8 py-3">
              <p className="text-gray-500 font-medium">No data found......</p>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Table Head={Thead}></Table>
          <div className=" flex justify-center items-center h-[400px] capitalize text-gray-600 font-semibold text-xl">
            {!showLoader && `no data available yet`}
          </div>
        </div>
      )}
    </ContextApi>
  );
}

export default TransactionReport;
