import React, { useState } from "react";
// import { CircularProgressbar } from "react-circular-progressbar";
// import "react-circular-progressbar/dist/styles.css";
// import axios from "../../../../HOC/CreateAxios/CreateAxios";
// import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// import storage from "../../../../HOC/FIREBASE/FIREBASE";
// import { PiProhibitInsetFill } from "react-icons/pi";
import ContextApi from "../../HOC/ContextApi/ContextApi";
// import axios from "../../HOC/Axios/Axios";
import DepositTablebyUSDT from "../../components/pageComponents/Deposit/DepositTablebyUSDT";
import DepositTablebyBank from "../../components/pageComponents/Deposit/DepositTablebyBank";

function DepositReport() {
  // const [showData, setShowData] = useState(false);

  // console.log(StartValue, EndValue);
  //   const Submit = async (val) => {
  //     try {

  //       axios
  //         .post("/Category", val)
  //         .then((res) => {
  //           if (res.status === 200) {
  //             toast.success("Category has been added sucessfully");
  //           }
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  // let Spinner;
  //   if (progressShow) {
  //     Spinner = (
  //       <div
  //         style={{ background: "rgba(0,0,0,.5)" }}
  //         className="fixed h-screen w-screen top-0 left-0 z-50 flex items-center justify-center"
  //       >
  //         <div className="h-44 w-44  rounded-md z-50">
  //           <CircularProgressbar value={progress} text={`${progress}%`} />
  //         </div>
  //       </div>
  //     );
  //   }
  // const Submit = () => {
  //   console.log("first");
  // };

  // const Tbody = [
  //   {
  //     name: "apple",
  //     createdAt: "2022/11/05",
  //     image: image,
  //     status: "accepted",
  //   },
  //   {
  //     name: "banana",
  //     createdAt: "2022/11/05",
  //     image: image,
  //     status: "rejected",
  //   },
  //   {
  //     name: "strawberry",
  //     createdAt: "2022/11/05",
  //     image: image,
  //     status: "rejected",
  //   },
  //   {
  //     name: "pineapple",
  //     createdAt: "2022/11/05",
  //     image: image,
  //     status: "accepted",
  //   },
  //   // { name: "pineapple", createdAt: "2022/11/05", image: image },
  //   // { name: "pineapple", createdAt: "2022/11/05", image: image },
  //   // { name: "pineapple", createdAt: "2022/11/05", image: image },
  //   // { name: "pineapple", createdAt: "2022/11/05", image: image },
  //   // { name: "pineapple", createdAt: "2022/11/05", image: image },
  //   // { name: "pineapple", createdAt: "2022/11/05", image: image },
  // ];

  // const HandleSearchInputChange = (e) => {
  //   // console.log(e.target.value);
  //   setFilteredData(e.target.value);
  // };

  // const Filter = (options) => {
  //   return options.filter((option, index) => {
  //     return (
  //       option["accountHolderName"]
  //         .toLowerCase()
  //         .indexOf(filteredData.toLowerCase()) > -1
  //       // ||
  //       // option["accountHolderName"].toLowerCase().indexOf(filteredData.toLowerCase()) > -1
  //     );
  //   });
  // };
  //   const DeleteItems = (i) => {
  //     let removeItems = storeData;
  //     removeItems.splice(i, 1);
  //     setStoreData([...removeItems]);
  //     setMessage(false);
  //   };
  // const DeleteItems = () => {
  //   console.log("delete");
  // };
  // const Conformation = (id) => {
  //   setMessage(true);
  //   // setIdStore(id);
  //   // console.log(id);
  // };

  // let ConformationMessage;
  // if (message) {
  //   ConformationMessage = (
  //     <MasterDeleteModal
  //       // id={idStore}
  //       Delete={DeleteItems}
  //       setMessage={setMessage}
  //     />
  //   );
  // }

  const [storeValue, setStoreValue] = useState("bank");

  const data = [
    {
      title: "bank",
      type: "radio",
      apikey: "paymentMethod",
      value: "bank",
    },
    {
      title: "usdt",
      type: "radio",
      apikey: "paymentMethod",
      value: "usdt",
    },
  ];
  const handleDataClick = () => {
    let mod;
    switch (storeValue) {
      case "bank":
        mod = <DepositTablebyBank />;
        break;
      case "usdt":
        mod = <DepositTablebyUSDT />;
        break;
      default:
        break;
    }
    return mod;
  };

  return (
    <ContextApi>
      {/* {showData && <ImageModel setShowData={setShowData} />} */}
      <div className=" col-span-8  w-full   ">
        <div className=" flex px-8 gap-4 mt-5">
          {data.map((btn, index) => {
            return (
              <div key={index} className="flex gap-2   font-bold uppercase">
                <div
                  className={`w-6 h-6 rounded-full cursor-pointer  ${
                    storeValue === btn.title
                      ? "border-2 border-[#833cdc]"
                      : "null"
                  } flex items-center justify-center  `}
                  onClick={() => setStoreValue(btn.title)}
                >
                  <div
                    className={`w-3 h-3 rounded-full ${
                      storeValue === btn.title
                        ? "  bg-[#833cdc]  "
                        : "bg-[#833cdc]"
                    }`}
                  ></div>
                  {/* <input
                        type={btn.type}
                        name={btn.apikey}
                        value={btn.value}
                        checked={storeValue === btn.value}
                        onChange={(e) => setStoreValue(e.target.value)}
                        className={`text-blue-500 w-5 h-5 focus:ring-blue-500 ${
                          storeValue === btn.value ? "checked-radio  " : ""
                        }`}
                      /> */}
                </div>
                <div className="">{btn.title}</div>
              </div>
            );
          })}
        </div>
        {/* {console.log(storeValue)} */}
        <div className="">{handleDataClick()}</div>
      </div>
    </ContextApi>
  );
}

export default DepositReport;
