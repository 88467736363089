import React, { useState, useEffect } from "react";
// import image from "../../Resources/Images/bitBgRemove.png";
import { ToastContainer, toast } from "react-toastify";
import Table from "../../UI/Table";
// import TableFilter from "../../components/pageComponents/TableFilter/TableFilter";
import ImageModel from "../../UI/ImageModel/ImageModel";
import MasterFormModel from "../../UI/RejectionFormModel/RejectionFormModel";
import MasterDeleteModal from "../../UI/MasterDeleteModal/MasterDeleteModal";
import ContextApi from "../../HOC/ContextApi/ContextApi";
import axios from "../../HOC/Axios/Axios";
import Loader from "../../components/pageComponents/Spinner/loader";
import Spinner from "../../components/pageComponents/Spinner/Spinner";
function DepositByQRCode() {
  const [modelClick, setModelClick] = useState(false);
  // const [filteredData,] = useState("");
  const [storeId, setstoreId] = useState("");
  const [storeAcceptID, setStoreAcceptID] = useState("");
  const [status, setStatus] = useState("");
  const [clickReject, setClickReject] = useState(false);
  const [message, setMessage] = useState(false);
  const [counter, setCounter] = useState(1);
  const [ID, setID] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [pagination] = useState({
    start: 0,
    end: 10,
  });

  const EndValue = counter * pagination.end;
  const StartValue = EndValue - pagination.end;
  // console.log(StartValue, EndValue);

  const Thead = [
    { title: "s.nO", with: "20%" },
    // { title: "name", with: "20%" },
    { title: "date", with: "20%" },
    { title: "voucherImage", with: "20%" },
    { title: "status", with: "20%" },
    { title: "action", with: "20%" },
  ];

  // const HandleSearchInputChange = (e) => {
  //   // console.log(e.target.value);
  //   setFilteredData(e.target.value);
  // };

  // const Filter = (options) => {
  //   return options.filter((option, index) => {
  //     return (
  //       option["createdAt"].toLowerCase().indexOf(filteredData.toLowerCase()) >
  //       -1
  //     );
  //   });
  // };
  //   const DeleteItems = (i) => {
  //     let removeItems = storeData;
  //     removeItems.splice(i, 1);
  //     setStoreData([...removeItems]);
  //     setMessage(false);
  //   };
  const getUserData = () => {
    setShowLoader(true);
    axios
      .get(`/deposit/getQrDeposit`)
      .then((res) => {
        console.log(res.data.data);
        setTableData(res?.data?.data);
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };
  useEffect(() => {
    getUserData();
  }, []);
  const Conformation = (val) => {
    setMessage(true);
    setStoreAcceptID(val);
  };
  const Submit = () => {
    const status = "completed";
    setShowSpinner(true);
    axios
      .patch(`/deposit/updateQrDepositStatus/${storeAcceptID.id}`, {
        status: status,
      })
      .then((res) => {
        if (res.status === 200) {
          toast.info("Updated status sucessfully");
          setTimeout(() => {
            setShowSpinner(false);
            setMessage(false);
            getUserData();
          }, 900);
        }
      })
      .catch((err) => {
        console.log(err);
        setShowSpinner(false);
      });
  };

  let ConformationMessage;
  if (message) {
    ConformationMessage = (
      <MasterDeleteModal
        message={message}
        content={"conformation message to accept?"}
        Submited={Submit}
        storeAcceptID={storeAcceptID}
        setMessage={setMessage}
        getUserData={getUserData}
      />
    );
  }

  const handleReject = (id) => {
    const status = "rejected";
    setStatus(status);
    setstoreId(id);
    setClickReject(true);
  };
  const [tableData, setTableData] = useState([]);

  const pendingData = tableData.filter((val) => {
    return val.status === "pending";
  });
  const handleImage = (val) => {
    setModelClick(true);
    setID(val);
  };
  return (
    <ContextApi>
      {showLoader && <Loader message={showLoader} />}
      {showSpinner && <Spinner message={showSpinner} />}
      <ToastContainer autoClose={1000} />
      {ConformationMessage}

      {clickReject ? (
        <MasterFormModel
          setClickReject={setClickReject}
          clickReject={clickReject}
          Id={storeId}
          status={status}
          getUserData={getUserData}
          title="deposit rejection by usdt"
          message={clickReject}
        />
      ) : (
        ""
      )}
      {modelClick && ID.id && (
        <ImageModel
          image={ID?.recipt}
          message={modelClick}
          setModelClick={setModelClick}
        />
      )}

      {/* <TableFilter
          HandleSearchInputChange={HandleSearchInputChange}
          Filter={Filter}
          placeholder={"search by date..."}
        /> */}
      {tableData.length > 0 && pendingData.length > 0 ? (
        <div className="overflow-x-auto">
          {tableData.length > 0 ? (
            <Table
              Head={Thead}
              counter={counter}
              setCounter={setCounter}
              data={pendingData}
              paginationEnd={pagination.end}
            >
              {pendingData.slice(StartValue, EndValue).map((val, i) => {
                return (
                  <tr
                    key={i}
                    width={val.width}
                    className="border-b text-center hover:bg-white"
                  >
                    <td className="px-3 py-7 ">{StartValue + 1 + i}</td>
                    {/* <td className="px-3 capitalize font-medium">{val?.name}</td> */}
                    <td className="px-1 text-sm">
                      {new Date(val?.createdAt).toDateString()}
                    </td>
                    <td className="px-3" onClick={() => handleImage(val)}>
                      <div className="flex justify-center">
                        <img
                          src={val?.recipt}
                          alt="Loading..."
                          width={20}
                          height={20}
                          className="w-16 h-16 rounded-full hover:scale-105 cursor-pointer object-cover overflow-hidden bg-black -900 object-top "
                        />
                      </div>
                    </td>
                    <td className="px-3 text-sm text-yellow-500 capitalize">
                      {val?.status}
                    </td>

                    <td className="px-3">
                      <div
                        className=" p-2.5 capitalize text-sm flex gap-3 items-center  justify-center
                     border-gray-300 "
                      >
                        <div
                          className="px-3 py-1.5 border w-20 hover:bg-green-600 bg-white hover:text-white  rounded-[3px]  cursor-pointer"
                          onClick={() => Conformation(val)}
                        >
                          accept
                        </div>
                        <div
                          className="px-3 py-1.5 border w-20 hover:bg-red-500 bg-white hover:text-white  rounded-[3px]  cursor-pointer"
                          onClick={() => handleReject(val.id)}
                        >
                          reject
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </Table>
          ) : (
            <div className="text-left px-8 py-3">
              <p className="text-gray-500 font-medium">No data found......</p>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Table Head={Thead}></Table>
          <div className=" flex justify-center items-center h-[400px] capitalize text-gray-600 font-semibold text-xl">
            {!showLoader && `no data available yet`}
          </div>
        </div>
      )}
    </ContextApi>
  );
}

export default DepositByQRCode;
