import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import ContextApi from "../../../HOC/ContextsAPI/ContextApi";

function PageNotFound() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/404Error");
  }, [show, navigate]);

  const navigateToDashboard = () => {
    Cookies.get("adminToken") ? navigate("/") : navigate("/login");
  };
  const redirectTologin = () => {
    Cookies.remove("adminToken");
    navigate("/login");
  };
  console.log(Cookies.get("c_user"));
  return (
    // <ContextApi>
    <div className="flex  flex-col justify-center items-center  h-screen">
      <div
        onClick={() => setShow(true)}
        className="text-4xl  text-gray-500  font-semibold "
      >
        No Page Found. Go Back To Main page...
      </div>
      {!Cookies.get("adminToken") ? (
        <div
          onClick={() => redirectTologin()}
          className="cursor-pointer bg-gray-800 w-fit mx-auto px-5 py-2
         text-white hover:scale-105 transition-all ease-in-out delay-75 duration-300 mt-20 "
        >
          Go back to login
        </div>
      ) : (
        <div
          onClick={() => navigateToDashboard()}
          className="cursor-pointer bg-gray-800 w-fit mx-auto px-5 py-2
         text-white hover:scale-105 transition-all ease-in-out delay-75 duration-300 mt-20 "
        >
          Go back to dashboard
        </div>
      )}
    </div>
    // {/* </ContextApi> */}
  );
}

export default PageNotFound;
