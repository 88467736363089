import React, { useState, useEffect } from "react";
import TableFilter from "../TableFilter/TableFilter";
import Table from "../../../UI/Table";
import ImageModel from "../../../UI/ImageModel/ImageModel";
import axios from "../../../HOC/Axios/Axios";
import Loader from "../Spinner/loader";
function DepositTablebyUSDT() {
  const [modelClick, setModelClick] = useState(false);
  const [filteredData, setFilteredData] = useState("");
  const [counter, setCounter] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [pagination] = useState({
    start: 0,
    end: 10,
  });
  const EndValue = counter * pagination.end;
  const StartValue = EndValue - pagination.end;

  // const [showData, setShowData] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [ID, setID] = useState("");
  //   const showAllData = () => {
  //     setShowData(true);
  //   };

  const Thead = [
    { title: "s.nO", with: "10%" },
    { title: "name", with: "10%" },
    // { title: "amount", with: "15%" },
    { title: "date", with: "10%" },
    { title: "voucherImage", with: "10%" },
    { title: "status", with: "10%" },
    // { title: "action", with: "15%" },
  ];
  const HandleSearchInputChange = (e) => {
    // console.log(e.target.value);
    setFilteredData(e.target.value);
  };
  const Filter = (options) => {
    return options.filter((option, index) => {
      return (
        option?.user?.fullName
          .toLowerCase()
          .indexOf(filteredData.toLowerCase()) > -1
        // ||
        // option["accountHolderName"].toLowerCase().indexOf(filteredData.toLowerCase()) > -1
      );
    });
  };
  const getDepositBankUserData = () => {
    setShowLoader(true);

    axios
      .get(`/deposit/getQrDeposit`)
      .then((res) => {
        // console.log(res.data.data);
        setTableData(res.data.data);
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };
  useEffect(() => {
    getDepositBankUserData();
  }, []);
  // console.log(tableData);
  let pendingData = tableData.filter((val) => {
    return val.status !== "pending" && val.user !== null;
  });

  const handleImage = (val) => {
    setModelClick(true);
    setID(val);
  };
  return (
    <div>
      {showLoader && <Loader message={showLoader} />}
      {modelClick && ID.id && (
        <ImageModel
          image={ID?.recipt}
          message={modelClick}
          setModelClick={setModelClick}
        />
      )}
      <TableFilter
        HandleSearchInputChange={HandleSearchInputChange}
        Filter={Filter}
        placeholder={"Search by name..."}
      />
      {tableData.length > 0 && pendingData.length > 0 ? (
        <div className="overflow-x-auto">
          {Filter(pendingData).length > 0 ? (
            <Table
              Head={Thead}
              counter={counter}
              setCounter={setCounter}
              data={pendingData}
              paginationEnd={pagination.end}
            >
              {Filter(pendingData)
                .slice(StartValue, EndValue)
                .map((val, i) => {
                  return (
                    <tr
                      key={i}
                      width={val.width}
                      className="border-b text-center hover:bg-white"
                    >
                      <td className="px-3 py-7 ">{StartValue + 1 + i}</td>
                      <td className="px-3 capitalize font-semibold">
                        {val?.user?.fullName}
                      </td>

                      {/* <td className="px-1 text-sm">{val?.amount}</td> */}
                      <td className="px-1 text-xs">
                        {new Date(val?.createdAt).toDateString()}
                      </td>
                      <td className="px-3" onClick={() => handleImage(val)}>
                        <div className="flex justify-center">
                          {" "}
                          <img
                            src={`${val?.recipt}`}
                            alt="Loading..."
                            width={20}
                            height={20}
                            className="w-14 h-14 rounded-full hover:scale-105 cursor-pointer object-cover overflow-hidden bg-black -900 object-top "
                          />
                        </div>
                      </td>
                      <td>{val.status}</td>
                      {/* <td className="px-3">
                      {val.status === "completed" ? (
                        <div className=" flex gap-4 justify-center items-center">
                          <TiTick className="text-4xl text-green-600" />{" "}
                          <BsFillInfoCircleFill className="text-sm cursor-pointer text-gray-400" />
                        </div>
                      ) : (
                        <div className="  flex  gap-4 justify-center items-center">
                          <PiProhibitInsetFill className="-rotate-45 text-4xl text-red-600 " />{" "}
                        </div>
                      )}
                    </td> */}
                    </tr>
                  );
                })}
            </Table>
          ) : (
            <div className="text-left px-8 py-3">
              <p className="text-gray-500 font-medium">No data found......</p>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Table Head={Thead}></Table>
          <div className=" flex justify-center items-center h-[400px] capitalize text-gray-600 font-semibold text-xl">
            {!showLoader && `no data available yet`}
          </div>
        </div>
      )}
    </div>
  );
}

export default DepositTablebyUSDT;
