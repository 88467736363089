import React, { useState, useEffect } from "react";
// import Spinner from "../components/UI/Spinner/Spinner";
// import PasswordToolbar from "../components/PageComponents/HomeSection/subcomponents/PasswordToolbar";
// import Footer from "../../HOC/Footer/Footer";
// import Logo from "../../Resources/Images/LoginPage.png";
import { Formik, Form, Field } from "formik";
// import * as Yup from "yup";
import { NavLink, useNavigate } from "react-router-dom";
// import { AiFillInstagram } from "react-icons/ai";
// import { BsTwitter, BsWhatsapp } from "react-icons/bs";
// import { FaFacebookF, FaTiktok } from "react-icons/fa";
import { forgotPasswordSchema } from "../../UI/RegxSchema/RegXSchema";
import Loader from "../../components/pageComponents/Spinner/loader";
import axios from "../../HOC/Axios/Axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
function UpdatePassword() {
  const [showSpinner, setShowSpinner] = useState(false);
  // const [showPassword, setShowPassword] = useState({});

  const navigate = useNavigate();
  const updatePassword = [
    {
      label: "email",
      type: "text",
      apikey: "email",
      placeholder: "Enter your email address",
    },

    {
      label: "old password",
      type: "text",
      apikey: "oldPassword",
      placeholder: "Enter old Password",
      icon1: <AiFillEye />,
      icon2: <AiFillEyeInvisible />,
    },
    {
      label: "new password",
      type: "text",
      apikey: "newPassword",
      placeholder: "Enter new Password",
      icon1: <AiFillEye />,
      icon2: <AiFillEyeInvisible />,
    },
    {
      label: "confirm new password",
      type: "text",
      apikey: "confirmNewPassword",
      placeholder: "Confirm your new Password",
      icon1: <AiFillEye />,
      icon2: <AiFillEyeInvisible />,
    },
  ];

  const handleSubmit = async (values, resetForm) => {
    console.log(values);
    setShowSpinner(true);
    try {
      let response = await axios.patch("/admin/changepassword", values);
      console.log(response);
      toast.success("Password updated sucessfully", { toastId: "Error" });
      setInterval(() => {
        if (response.status === 200) {
          setShowSpinner(false);
          navigate("/login");
          resetForm();
        }
      }, 300);
    } catch (error) {
      setShowSpinner(false);
      toast.error(error.message, { toastId: "Error" });
    }
  };
  // const [ID, setID] = useState({});
  // const handleClicked = (apikey) => {
  //   setID((prev) => ({ ...prev, [apikey]: true }));
  // };
  // const handleInputBlur = (e, apikey) => {
  //   if (e.target.value.length > 0) {
  //     setID((prev) => ({ ...prev, [apikey]: true }));
  //   } else {
  //     setID((prev) => ({ ...prev, [apikey]: false }));
  //   }
  // };
  // const handleHidePassword = (apikey) => {
  //   setShowPassword({ ...showPassword, [apikey]: false });
  // };
  // const handleShowPassword = (apikey) => {
  //   setShowPassword({ ...showPassword, [apikey]: true });
  // };
  useEffect(() => {
    // Disable scrolling when the component mounts
    document.body.style.overflow = "hidden";

    // Re-enable scrolling when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div className="  tablet:h-fit p-5 py-10   ">
      {showSpinner && <Loader />}
      <ToastContainer autoClose={1000} pauseOnHover={false} />
      <div className="  items-start justify-center  flex-col">
        <Formik
          initialValues={{
            email: "",
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
          }}
          validationSchema={forgotPasswordSchema}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, resetForm);
          }}
        >
          {({ errors, touched, values }) => (
            <Form>
              <div className="px-5  tablet:grid grid-cols-2 gap-6">
                {updatePassword.map((val, i) => {
                  return (
                    <div className="  relative " key={i}>
                      <label
                        // onClick={() => handleClicked(val.apikey)}
                        htmlFor={val.apikey}
                        className="text-[16px] capitalize font-semibold"
                        // className={` absolute tracking-wider
                        //     ${
                        //       ID[val.apikey] || values[val.apikey]
                        //         ? "top-2 text-[12px] font-normal"
                        //         : "top-6 text-[14px] font-medium"
                        //     }
                        //    left-5 transition-all ease-in-out duration-300 text-gray-500 `}
                      >
                        {val.label}
                      </label>
                      <div className="my-3">
                        <Field
                          // onFocus={() => handleClicked(val.apikey)}
                          // onBlur={(e) => handleInputBlur(e, val.apikey)}
                          id={val.apikey}
                          type={val.type}
                          name={val.apikey}
                          placeholder={val.placeholder}
                          className="   px-5  h-14 outline-none border border-gray-400 rounded-sm w-full"
                        />
                        {/* <div className="absolute top-7 right-5 cursor-pointer">
                          {showPassword[val.apikey] ? (
                            <div onClick={() => handleHidePassword(val.apikey)}>
                              {val.icon1}
                            </div>
                          ) : (
                            <div onClick={() => handleShowPassword(val.apikey)}>
                              {val.icon2}
                            </div>
                          )}
                        </div> */}
                      </div>
                      {errors[val.apikey] && touched[val.apikey] ? (
                        <div className=" text-red-400  mb-3 text-sm px-2  rounded-sm">
                          {errors[val.apikey]}
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
              <div className="   py-2 px-4">
                <button
                  type="submit"
                  className="bg-primary  text-sm font-semibold    w-fit outline-none text-white bg-[#833CDC] flex items-center justify-center
                     rounded-md hover:bg-opacity-80 transition-all duration-300 delay-75 ease-in-out 
                     cursor-pointer py-2.5  px-7"
                >
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default UpdatePassword;
