import React, { useEffect } from "react";
// import { GiSpineArrow } from "react-icons/gi";

function Spinner() {
  useEffect(() => {
    let interval = setTimeout(() => {
      document.body.style.overflow = "hidden";
    }, 1000);
    return () =>
      clearTimeout(interval, (document.body.style.overflow = "auto"));
  }, []);

  return (
    <div className=" bg-black bg-opacity-30 fixed  left-0 top-0   z-[900] w-screen h-screen ">
      <div className="spiner flex items-center justify-center  h-screen tablet:mr-24  ">
        <svg viewBox="0 0 1900 100">
          <text df="5em" x="50%" y="50%">
            please wait...
          </text>
        </svg>
      </div>
    </div>
  );
}

export default Spinner;
