import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
// import { AuthContext } from "../HOC/ContextApi/AuthCheck";
import Layout from "../HOC/Layout/layout";
import DashBoard from "../pages/DashBoard";
import DepositByQRCode from "../pages/Deposit/DepositByQRCode";
import DepositByBank from "../pages/Deposit/DepositByBank";
import Trading from "../pages/Trading/index";
import Contact from "../pages/Contact";
import PageNotFound from "../pages/PageNotFound";
import Login from "../pages/Login";
import WithdrawByBank from "../pages/Withdraw/WithdrawByBank";
import WithdrawByQRCode from "../pages/Withdraw/WithdrawByQrCode";
import DepositReport from "../pages/Report/DepositReport";
import WithDrawReport from "../pages/Report/WithdrawReport";
import TransactionReport from "../pages/Report/TransactionReport";
import SetttleByBank from "../pages/Settle/SetttleByBank";
import SettleByQRCode from "../pages/Settle/SettleByQRCode";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import WalletSetting from "../pages/WalletSetting";
import UserDetails from "../pages/UserDetails";
import ViewSingleUser from "../pages/UserDetails/ViewSingleUser";
import CreateAdminAccount from "../pages/CreateAdminAccount";
import UpdatePassword from "../pages/UpdatePassword";
// import ViewSingleUser from "../pages/UserDetails/[id]";

function App() {
  return (
    <div className="">
      <Router>
        <Routes>
          <Route element={<Layout />}>
            <Route exact path="/" element={<DashBoard />} />
            <Route exact path="/bank_deposit" element={<DepositByBank />} />
            <Route exact path="/usdt_deposit" element={<DepositByQRCode />} />
            <Route exact path="/trading" element={<Trading />} />
            <Route exact path="/user_details" element={<UserDetails />} />
            <Route
              exact
              path="/user_details/:id"
              element={<ViewSingleUser />}
            />

            <Route exact path="/bank_withdrawal" element={<WithdrawByBank />} />
            <Route
              exact
              path="/usdt_withdrawal"
              element={<WithdrawByQRCode />}
            />
            <Route exact path="/bank_settle" element={<SetttleByBank />} />
            <Route exact path="/usdt_settle" element={<SettleByQRCode />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/deposit_report" element={<DepositReport />} />
            <Route
              exact
              path="/withdrawal_report"
              element={<WithDrawReport />}
            />
            <Route
              exact
              path="/transaction_report"
              element={<TransactionReport />}
            />

            <Route exact path="/wallet_setting" element={<WalletSetting />} />
            <Route exact path="/update_password" element={<UpdatePassword />} />
            <Route
              exact
              path="/create_admin_account"
              element={<CreateAdminAccount />}
            />
          </Route>
          <Route exact path="/forgotPassword" element={<ForgotPassword />} />
          <Route exact path="/resetPassword" element={<ResetPassword />} />
          <Route exact path="*" element={<PageNotFound />} />
          <Route exact path="/login" element={<Login />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
