import React, { useState, useEffect } from "react";
import Sidebar from "../../components/navigation/SideBar/SideBar";
import TopBar from "../../components/navigation/TopBar/TopBar";
import { Outlet } from "react-router-dom";
import { MdClose } from "react-icons/md";
function Layout() {
  const [showSidebar, setShowSidebar] = useState(false);
  const [show, setShow] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(false);
  useEffect(() => {
    if (show === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    // Cleanup function to reset overflow when component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [show]);
  return (
    <div className=" ">
      {/* small device below from tablet size */}

      <div className=" block  scroll laptopM:hidden ">
        <div
          className={` z-10 ${
            show ? "fixed h-screen w-full" : ""
          } grid grid-cols-12   `}
          style={{
            background: "rgba(0,0,0,.3)",
          }}
        >
          <div
            className={` col-span-9  scroll ${
              show
                ? " translate-x-0  relative  transition-all delay-200 duration-700 ease-in-out"
                : "-translate-x-full fixed  "
            } `}
          >
            <Sidebar
              showSidebar={showSidebar}
              show={show}
              setShow={setShow}
              setShowSidebar={setShowSidebar}
              setShowCancelButton={setShowCancelButton}
            />
          </div>
          <div
            className="w-full  col-span-3 "
            onClick={() => {
              setShow(false);
              setShowCancelButton(false);
            }}
          >
            {showCancelButton && (
              <div className="absolute z-50 right-3 tablet:left-80 tablet:top-5">
                <MdClose
                  className={` h-10 w-10  text-white  laptopM:text-white
                  laptopM:bg-transparent hover:scale-105 transition-all duration-300 ease-in-out
                      cursor-pointer  border border-white rounded-full p-1.5 mobileS:bg-white
                       mobileS:text-black  laptopM:hover:bg-white  laptopM:hover:text-black  my-2 `}
                />
              </div>
            )}
          </div>
        </div>

        <div>
          <TopBar setShow={setShow} show={show} />
          <Outlet />
        </div>
      </div>
      {/* large device from tablet screen size */}
      <div
        className={`    ${
          showSidebar
            ? "hidden  laptopM:flex"
            : " hidden laptopM:grid grid-cols-12"
        }`}
      >
        <div
          className={` fixed  z-20 ${
            showSidebar
              ? "w-16   transition-all ease-in-out delay-75 duration-200 "
              : " w-64  desktop:w-full col-span-2 transition-all ease-out  "
          }  `}
        >
          <Sidebar
            setShow={setShow}
            show={show}
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
            setShowCancelButton={setShowCancelButton}
          />
        </div>
        <div
          className={`   ${
            showSidebar ? "w-[96%]   ml-auto" : "col-span-10 col-start-3 "
          }`}
        >
          <TopBar
            // showSidebar={showSidebar}
            // setShowSidebar={setShowSidebar}
            setShow={setShow}
            show={show}
          />
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Layout;
