import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../HOC/Axios/Axios";
// import * as yup from "yup";
// import img from "../../../Resources/Logo.png";
import { buySchema } from "../../../UI/RegxSchema/RegXSchema";
import Spinner from "../../../components/pageComponents/Spinner/Spinner";
import { IoClose } from "react-icons/io5";
import Loader from "../Spinner/loader";
function UserDetailFormModel({ setShowUserForm, userIds, getUserData }) {
  const [Images, setImages] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [datas, setDatas] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const buyTime = [
    { id: 1, option: "1" },
    { id: 2, option: "2" },
    { id: 3, option: "3" },
  ];
  let buyTimeOpt = [];
  buyTime.map((item) => {
    return buyTimeOpt.push(item);
  });
  //   console.log(userIds);
  const userForm = [
    {
      label: "full name",
      type: "text",
      apikey: "fullName",
      placeholder: "full name",
    },
    {
      label: "email",
      type: "text",
      apikey: "email",
      placeholder: "email",
    },
    // {
    //   label: "password",
    //   type: "text",
    //   apikey: "password",
    //   placeholder: "password",
    // },
    {
      label: "country",
      type: "text",
      apikey: "country",
      placeholder: "country",
    },
    {
      label: "phoneNo",
      type: "number",
      apikey: "phoneNo",
      placeholder: "phoneNo",
    },

    {
      label: "address",
      type: "text",
      apikey: "address",
      placeholder: "address",
    },
    {
      label: "credit score",
      type: "text",
      apikey: "creditScore",
      placeholder: "credit score",
    },
    {
      // id: 0,
      label: "legal document",
      type: "file",
      apikey: "validId",
    },
    {
      // id: 1,
      label: "profile image",
      type: "file",
      apikey: "profile",
    },
  ];
  const images = [
    {
      id: 0,
      label: "legal document",
      type: "file",
      apikey: "validId",
    },
    {
      id: 1,
      label: "profile image",
      type: "file",
      apikey: "profile",
    },
  ];
  // const buySchema = yup.object().shape({
  //   fullName: yup.string().required("required"),
  //   email: yup.string().required("required"),
  //   // password: yup.string().required("required"),
  //   country: yup.string().required("required"),
  //   phoneNo: yup.string().required("required"),
  //   address: yup.string().required("required"),
  //   // creditScore: yup.string().required("required"),
  // });

  let loader;
  if (showSpinner) {
    loader = (
      <div className="fixed top-0 left-0 bg-black bg-opacity-50 w-full h-screen z-[900]">
        <Spinner />
      </div>
    );
  }

  const handleImageChange = (e, apikey) => {
    const newImages = { ...Images };
    newImages[apikey] = e.target.files[0];
    setImages(newImages);
  };

  const handleRemoveImage = (apikey) => {
    const newImages = { ...Images };
    delete newImages[apikey];
    setImages(newImages);
    document.getElementById(apikey).value = null;
  };
  const Sumbit = (values) => {
    // e.preventDefault();
    console.log(Images);
    setShowSpinner(true);

    const formData = new FormData();
    formData.append("fullName", values.fullName);
    formData.append("email", values.email);
    formData.append("password", values.password);
    formData.append("country", values.country);
    formData.append("phoneNo", values.phoneNo);
    formData.append("address", values.address);
    formData.append("creditScore", values.creditScore);
    if (Images.validId) {
      formData.append("validId", Images.validId);
    }
    if (Images.profile) {
      formData.append("profile", Images.profile);
    }

    //  formData.append("validId", values.image.validId);
    //  formData.append("profile", values.image.profile);

    axios
      .patch(`/user/${userIds}`, formData)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success("User data has been updated sucessfully");
          setShowSpinner(false);
          setShowUserForm(false);
          getUserData();
        }
      })
      .catch((err) => {
        console.log(err);
        setShowSpinner(false);
      });
  };
  const getSingleUserData = async (values) => {
    // e.preventDefault();
    // console.log(Images);
    setShowLoader(true);

    axios
      .get(`/user/${userIds}`)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setDatas(res.data.result);
          setImages({
            validId: res.data.result.validId,
            profile: res.data.result.profile,
          });
          setShowLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };
  useEffect(() => {
    getSingleUserData();
  }, []);

  return (
    <div className=" bg-white fixed   px-32 w-full bottom-0 left-0 h-full overflow-y-auto">
      <ToastContainer autoClose={1000} />
      {loader}
      {showLoader && <Loader />}
      <div className="w-full text- [#303132]  relative">
        {datas && (
          <Formik
            initialValues={{
              fullName: datas.fullName ? datas.fullName : "",
              email: datas.email ? datas.email : "",
              password: datas.password ? datas.password : "",
              country: datas.country ? datas.country : "",
              phoneNo: datas.phoneNo ? datas.phoneNo : "",
              address: datas.address ? datas.address : "",
              creditScore: datas.creditScore ? datas.creditScore : "",
            }}
            onSubmit={async (values, { resetForm }) => {
              console.log(values);
              Sumbit(values);
            }}
            validationSchema={buySchema}
          >
            {({ handleSubmit, errors, touched, setFieldValue }) => {
              return (
                <Form className="  w-[95%]  py-7  rounded-[5px] mx-auto">
                  {/* <ToastContainer autoClose={1000} /> */}
                  <div className=" text-xl text-[#833cdc] font-bold">
                    {" "}
                    User Edit Form
                  </div>
                  <div className="text-base font-medium  flex justify-end  text-[#686868] ">
                    <button
                      className="bg-GrayLight border  mt-4 w-fit px-4 py-1.5 rounded-sm  capitalize "
                      onClick={() => setShowUserForm(false)}
                    >
                      back
                    </button>
                  </div>
                  <div className="  gap-7 laptop:gap-x-7 mt-5 laptop:gap-y-10 grid laptop:grid-cols-2  laptop:py-3 pb-7 laptop:pb-10 ">
                    {userForm.map((item, index) => {
                      if (item.type === "file") {
                        return null;
                        //   <div className="    relative " key={index}>
                        //     <div className="absolute  -top-3 left-3  px-2 ">
                        //       <label className="font-medium text-[13px]  mx-auto  px-3  text-[#9b9b9b] bg-[white]  capitalize">
                        //         {item.label}
                        //       </label>
                        //     </div>
                        //     <div
                        //       className=" border h-64 flex items-center justify-center border-[#c8c8c8]  "
                        //       key={index}
                        //     >
                        //       <input
                        //         id={item.apikey}
                        //         className="h-14   outline-none  hidden px-5 bg-white w-full
                        //         text-sm placeholder:text-xs sm:placeholder:text-sm focus:outline-primary"
                        //         type={item.type}
                        //         name={item.apikey}
                        //         placeholder={item.placeholder}
                        //         onChange={(e) =>
                        //           handleChangeImages(e, item.apikey)
                        //         }
                        //       />
                        //       <div>
                        //         <div className="my-3">
                        //           <img
                        //             src={Images[item.apikey]}
                        //             width={"100px"}
                        //             heiht={"100px"}
                        //             alt="Uploaded"
                        //             className="bg-gray-200"
                        //           />

                        //           <label htmlFor={item.apikey}>
                        //             <div className="bg-gray-200 w-fit mx-auto p-2">
                        //               add images
                        //             </div>
                        //           </label>
                        //         </div>
                        //       </div>
                        //     </div>

                        //     {errors[item.apikey] && touched[item.apikey] ? (
                        //       <div className="text-red-500 text-sm mt-1 capitalize px-2">
                        //         {errors[item.apikey]}
                        //       </div>
                        //     ) : (
                        //       ""
                        //     )}
                        //   </div>
                      } else {
                        return (
                          <div className="   relative " key={index}>
                            <div className="absolute  -top-3 left-3  px-2 ">
                              <label className="font-medium text-[13px] bg-[white] mx-auto  px-3  text-[#9b9b9b]   capitalize">
                                {item.label}
                              </label>
                            </div>
                            <div
                              className=" border border-[#ababab] "
                              key={index}
                            >
                              <Field
                                className="h-14 bg-white   outline-none  px-5  w-full   
                                  text-sm placeholder:text-xs sm:placeholder:text-sm focus:outline-primary"
                                type={item.type}
                                name={item.apikey}
                                min={item.min ? item.min : null}
                                placeholder={item.placeholder}
                              />
                            </div>

                            {errors[item.apikey] && touched[item.apikey] ? (
                              <div className="text-red-500 text-sm mt-1 capitalize px-2">
                                {errors[item.apikey]}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      }
                    })}
                  </div>
                  <div className=" laptop:my-4  gap-7  grid laptop:grid-cols-2 w-full">
                    {images.map((val, i) => {
                      return (
                        <div
                          className="relative border border-[#ababab]"
                          key={i}
                        >
                          <div className="absolute   -top-3 left-3  px-2 ">
                            <label className="font-medium text-[13px]  mx-auto  px-3  text-[#9b9b9b] bg-[white]  capitalize">
                              {val.label}
                            </label>
                          </div>
                          <div>
                            <input
                              id={val.apikey}
                              type={val.type}
                              name={val.apikey}
                              accept="image/png, image/gif, image/jpeg"
                              onChange={(e) => {
                                handleImageChange(e, val.apikey);
                              }}
                              className="hidden"
                            />
                          </div>
                          <div className="my-7  w-8/12  mx-auto overflow-hidden p-1 ">
                            {Images[val.apikey] ? (
                              <div>
                                <div>
                                  <img
                                    src={
                                      typeof Images[val.apikey] === "string"
                                        ? Images[val.apikey]
                                        : URL.createObjectURL(
                                            Images[val.apikey]
                                          )
                                    }
                                    alt="loading"
                                    width={100}
                                    height={100}
                                    accept="image/png, image/gif, image/jpeg"
                                    className=" object-contain h-64 w-full  object-top "
                                  />
                                </div>
                                <div
                                  className="absolute top-5 right-5 bg-red-500 rounded-full text-white p-1 cursor-pointer"
                                  onClick={() => {
                                    handleRemoveImage(val.apikey);
                                  }}
                                >
                                  <IoClose />
                                </div>
                              </div>
                            ) : (
                              <div className="h-64 w-full flex    justify-center items-center ">
                                <label htmlFor={val.apikey}>
                                  {" "}
                                  <div className="bg-gray-200 rounded-full  relative flex  cursor-pointer items-center justify-center w-28 h-28    ">
                                    <div className="flex text-lg capitalize absolute items-center justify-center">
                                      add banner
                                    </div>
                                  </div>
                                </label>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex flex-col mt-8 items-end  relative">
                    <button
                      className={`px-[47px] py-[10px] bg-[#833CDC] flex items-center gap-1 rounded-md text-white ${
                        Images ? "" : "cursor-not-allowed"
                      }`}
                      type="submit"
                      disabled={Images ? false : true}
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </div>
  );
}

export default UserDetailFormModel;
