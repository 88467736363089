import React from "react";

function Loader({ message }) {
  return (
    <div message={message}>
      <div className="  absolute  uppercase bg-blck bg-opacity-25 left-0 top-0 w-full  z-[900]   h-full ">
        <div className=" spinner flex items-center justify-center  w-full     ">
          {/* <svg viewBox="0 0 1000 100">
          <text df="5em" x="40%" y="40%">
            loading...
        </text>
        </svg> */}
        </div>
      </div>
    </div>
  );
}

export default Loader;
