import React, { useState, useEffect } from "react";
// import { Field, Form, Formik } from "formik";
// import { useNavigate } from "react-router-dom";
// import { CircularProgressbar } from "react-circular-progressbar";
// import "react-circular-progressbar/dist/styles.css";
import axios from "../../HOC/Axios/Axios";
// import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// import storage from "../../../../HOC/FIREBASE/FIREBASE";
// import * as yup from "yup";
// import image from "../../Resources/Images/bitBgRemove.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from "../../UI/Table";
import MasterDeleteModal from "../../UI/MasterDeleteModal/MasterDeleteModal";
import TableFilter from "../../components/pageComponents/TableFilter/TableFilter";
import ContextApi from "../../HOC/ContextApi/ContextApi";
// import { FaHandshakeSimple } from "react-icons/fa6";
import MasterFormModel from "../../UI/RejectionFormModel/RejectionFormModel";
import Loader from "../../components/pageComponents/Spinner/loader";
import Spinner from "../../components/pageComponents/Spinner/Spinner";
import ScrollOff from "../../components/pageComponents/WindowScrollOff/ScrollOff";
function SetttleByBank() {
  //   const [idStore, setIdStore] = useState(false);
  const [message, setMessage] = useState(false);
  // form names and keys for mapping
  // const [progress, setprogress] = useState(0);
  // const [progressShow, setprogressShow] = useState(false);
  // const Navigate = useNavigate();
  const [storeAcceptID, setStoreAcceptID] = useState("");
  const [storeUserId, setStoreUserId] = useState("");
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState("");
  const [counter, setCounter] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [pagination] = useState({
    start: 0,
    end: 10,
  });
  // pagination logic
  const EndValue = counter * pagination.end;
  const StartValue = EndValue - pagination.end;
  // console.log(StartValue, EndValue);
  const [clickReject, setClickReject] = useState(false);

  const Thead = [
    { title: "s.nO", with: "10%" },
    { title: "name", with: "10%" },
    { title: "bank", with: "10%" },
    { title: "accountHolderName", with: "20%" },
    { title: "accountNumber", with: "20%" },
    { title: "amount", with: "20%" },
    { title: "status", with: "10%" },
    { title: "action", with: "20%" },
  ];

  const HandleSearchInputChange = (e) => {
    // console.log(e.target.value);
    setFilteredData(e.target.value);
  };

  const Filter = (options) => {
    return options.filter((option, index) => {
      return (
        option["user"]["fullName"]
          .toLowerCase()
          .indexOf(filteredData.toLowerCase()) > -1 ||
        option["accountHolderName"]
          .toLowerCase()
          .indexOf(filteredData.toLowerCase()) > -1
      );
    });
  };
  //   const DeleteItems = (i) => {
  //     let removeItems = storeData;
  //     removeItems.splice(i, 1);
  //     setStoreData([...removeItems]);
  //     setMessage(false);
  //   };
  // const handleReject = () => {
  //   console.log("delete");
  // };
  const Submit = () => {
    const status = "settled";
    setShowSpinner(true);

    axios
      .patch(`/withdrawal/updateBankStatus/${storeAcceptID}`, {
        status: status,
        userId: storeUserId,
      })
      .then((res) => {
        if (res.status === 200) {
          toast.info("Updated status sucessfully");
          setTimeout(() => {
            setMessage(false);
            setShowSpinner(false);

            getUserData();
          }, 900);
        }
      })
      .catch((err) => {
        console.log(err);
        setShowSpinner(false);
      });
  };
  const Conformation = (id, userId) => {
    setMessage(true);
    setStoreUserId(userId);
    setStoreAcceptID(id);
    // console.log(id);
  };

  let ConformationMessage;
  if (message) {
    ConformationMessage = (
      <MasterDeleteModal
        content={"confirmation message to accept?"}
        Submited={Submit}
        setMessage={setMessage}
      />
    );
  }

  const getUserData = () => {
    setShowLoader(true);
    axios
      .get(`/withdrawal`)
      .then((res) => {
        console.log(res.data.data);
        setTableData(res?.data?.data);
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };
  useEffect(() => {
    getUserData();
  }, []);
  const completedData = tableData.filter((val) => {
    return val.status === "completed";
  });
  return (
    <ContextApi>
      <ScrollOff message={message}>{ConformationMessage}</ScrollOff>
      <ToastContainer autoClose={1000} />
      {showLoader && <Loader />}
      {showSpinner && <Spinner />}
      {clickReject ? (
        <MasterFormModel
          setClickReject={setClickReject}
          clickReject={clickReject}
        />
      ) : (
        ""
      )}

      <TableFilter
        HandleSearchInputChange={HandleSearchInputChange}
        placeholder={"search by name..."}
      />
      {tableData.length > 0 && completedData.length > 0 ? (
        <div className="overflow-x-auto">
          {Filter(completedData).length > 0 ? (
            <Table
              Head={Thead}
              counter={counter}
              setCounter={setCounter}
              data={completedData}
              paginationEnd={pagination.end}
            >
              {Filter(completedData)
                .slice(StartValue, EndValue)
                .map((val, i) => {
                  return (
                    <tr
                      key={i}
                      width={val.width}
                      className="border-b text-sm capitalize hover:bg-white text-center"
                    >
                      <td className="px-3 py-7 ">{StartValue + 1 + i}</td>
                      <td className="px-3 py-6 text-[15px] font-bold">
                        {val?.user?.fullName}
                      </td>
                      <td className="px-3   capitalize">{val?.bankName}</td>
                      <td className="px-3">{val?.accountHolderName}</td>
                      <td className="px-1">{val?.accountNo}</td>
                      <td className="px-1">{val?.withdrawalAmount}</td>

                      <td className="px-3text-sm font-light  text-green-500 capitalize">
                        {val?.status}
                      </td>

                      <td className="px-3">
                        <div
                          className=" p-2.5 capitalize text-sm flex gap-3 items-center  justify-center
                     border-gray-300 "
                        >
                          <div
                            className="px-3 py-1.5 border w-20 hover:bg-secondMainColor bg-white hover:text-white  rounded-[3px]  cursor-pointer"
                            onClick={() => Conformation(val.id, val?.user?.id)}
                          >
                            settle
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </Table>
          ) : (
            <div className="text-left px-8 py-3">
              <p className="text-gray-500 font-medium">No data found......</p>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Table Head={Thead}></Table>
          <div className=" flex justify-center items-center h-[400px] capitalize text-gray-600 font-semibold text-xl">
            {!showLoader && `no data available yet`}
          </div>
        </div>
      )}
    </ContextApi>
  );
}

export default SetttleByBank;
