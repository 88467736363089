import React from "react";
import { BsSearch } from "react-icons/bs";

function TableFilter({ HandleSearchInputChange, placeholder }) {
  return (
    <div className=" absolute top-4 z- left-[30%]  w-4/12 ">
      {/* <div className="py-5 px-1.5 laptop:px-8">
        <div className=" laptop:w-4/12 ">
          <input
            type="text"
            name="filter"
            // value=""
            onChange={(e) => HandleSearchInputChange(e)}
            placeholder={placeholder}
            className="w-full border border-[#d6d6d6] px-3 py-2 laptop:rounded-md outline-none placeholder:text-[#c8c8c8] -300 
   bg-mainColor"
          />
        </div>
      </div> */}
      <div className="flex gap-10 items-center w-full ">
        <div className="col-span-4   justify-start items-center w-full">
          <div className="  bg-white rounded-full w-full gap-x-4 h- pl-4 flex items-center   ">
            <div className=" pl-2">
              <label htmlFor="search">
                <BsSearch className="text-gray-400" />
              </label>
            </div>
            <div className="   w-full">
              <input
                id="search"
                type="text"
                name="filter"
                placeholder={placeholder}
                onChange={(e) => HandleSearchInputChange(e)}
                className="w-full placeholder:text-gray-400 text-black bg-mainColor border-2  border-white   rounded-full outline-none  py-2.5  pl-4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableFilter;
