import React, { useState } from "react";
import Table from "../../../UI/Table";
import Skeleton from "react-loading-skeleton";
function AdminsInfo({ infodata }) {
  const Head = [
    { title: "s.n", width: "30%" },
    { title: "email", width: "70%" },
    // { title: "action", width: "10%" },
  ];

  // const [showPassword, setShowPassword] = useState({});
  const [pagination] = useState({
    start: 0,
    end: 3,
  });
  const [counter, setCounter] = useState(1);
  const EndValue = counter * pagination.end;
  const StartValue = EndValue - pagination.end;
  // const toggleShowPassword = (id) => {
  //   setShowPassword((prevShowPassword) => ({
  //     ...prevShowPassword,
  //     [id]: !prevShowPassword[id],
  //   }));
  // };

  return (
    <div>
      <div className="mt-5 px-2 text-lg font-bold tablet:text-center underline tracking-wide">
        Admin Informations
      </div>
      <div className=" ">
        {infodata.length > 0 ? (
          <Table
            Head={Head}
            title="adminInfoTable"
            counter={counter}
            setCounter={setCounter}
            data={infodata}
            paginationEnd={pagination.end}
          >
            {infodata?.slice(StartValue, EndValue).map((val, i) => {
              return (
                <tr key={i} className="text-center hover:bg-white">
                  <td className="border-b py-3">{i + 1}</td>
                  <td className="border-b py-3">{val.email}</td>
                  <td className="border-b py-3   ">
                    <div className="flex items-center  justify-center gap-3">
                      {/* <div>
                      {" "}
                      {showPassword[val.id] ? val.password : "********"}
                    </div>
                    <div className="text-secondMainColor cursor-pointer">
                      {showPassword[val.id] ? (
                        <BsEye onClick={() => toggleShowPassword(val.id)} />
                      ) : (
                        <GoEyeClosed
                          onClick={() => toggleShowPassword(val.id)}
                        />
                      )}
                    </div> */}
                    </div>
                  </td>
                  {/* <td className="border-b py-3  ">
                  <div className=" flex gap-2 items-center justify-center">
                    <div className=" border px-2 py-1 w-fit  text-gray-600 hover:bg-mainColor cursor-pointer hover:text-secondMainColor">
                      <MdEdit className="text-lg " />
                    </div>
                    <div className=" border px-2 py-1 w-fit  text-gray-600 hover:bg-mainColor cursor-pointer hover:text-red-600">
                      <MdDelete className="text-lg " />
                    </div>
                  </div>
                </td> */}
                </tr>
              );
            })}
          </Table>
        ) : (
          <Skeleton className="h-40 tablet:h-60 tablet:mx-5" />
        )}
      </div>
    </div>
  );
}

export default AdminsInfo;
