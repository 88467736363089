import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
// import { BsPersonPlusFill } from "react-icons/bs";
// import { AiFillLock } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import cookies from "js-cookie";
import axios from "../../HOC/Axios/Axios";
import { AdminIdCreateSchema } from "../../UI/RegxSchema/RegXSchema";
import AdminsInfo from "../../components/pageComponents/AdminsInfo/Info";
import Spinner from "../../components/pageComponents/Spinner/Spinner";
function CreateAdminAccount() {
  const [showSpinner, setShowSpinner] = useState(false);
  const LoginForm = [
    {
      type: "email",
      label: "Enter email address",
      placeholder: "Enter email address",
      apikey: "email",
      autocomplete: "on",
    },
    {
      type: "text",
      label: "Password",
      placeholder: "Enter password",
      apikey: "password",
    },
    // {
    //   type: "text",
    //   label: "full name",
    //   placeholder: "Enter full name",
    //   apikey: "fullName",
    // },
    // {
    //   type: "number",
    //   label: "phone Number",
    //   placeholder: " Enter phone number",
    //   apikey: "phoneNo",
    // },
  ];
  console.log(showSpinner);
  const [infodata, setInfoData] = useState([]);
  const getInfoData = async () => {
    try {
      let res = await axios.get("/admin");
      console.log(res);
      setInfoData(res.data.companyInfo);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getInfoData();
  }, []);
  const submit = (values, resetForm) => {
    console.log(values);
    setShowSpinner(true);
    axios
      .post("/admin/signup", values)
      .then((res) => {
        if (res.status === 201) {
          toast.success("Admin id has been created sucessfully", {
            toastId: "AddedSuccess",
          });
          // (res.data.token);
          //   cookies.set("adminToken", res.data.token);
          setShowSpinner(false);
          setTimeout(() => {
            getInfoData();
            resetForm();
          }, 1500);
        }
      })
      .catch((err) => {
        console.log(err);

        toast.error(err.response.data.message, { toastId: "Error" });
        setShowSpinner(false);
      });
  };

  //   useEffect(() => {
  //     // Disable scrolling when the component mounts
  //     document.body.style.overflow = "hidden";

  //     // Re-enable scrolling when the component unmounts
  //     return () => {
  //       document.body.style.overflow = "auto";
  //     };
  //   }, []);
  return (
    <div className="px-3 tablet:px-8  tablet:grid grid-cols-2">
      <div className="">
        {showSpinner && <Spinner />}
        <ToastContainer autoClose={1000} pauseOnHover={false} />

        <Formik
          initialValues={{
            email: "",
            password: "",
            // fullName: "",
            // phoneNo: "",
          }}
          validationSchema={AdminIdCreateSchema}
          onSubmit={(values, { resetForm }) => {
            console.log(values);
            submit(values, resetForm);
          }}
        >
          {({ errors, touched, values }) => (
            <Form className=" w-full mx-auto">
              <div className="  w-full grid  my-8 grid-cols- gap-5 ">
                {LoginForm.map((val, i) => {
                  return (
                    <div key={i} className=" w-full">
                      <div className="relative  ">
                        <label
                          htmlFor={val.apikey}
                          className="capitalize mb-2 font-semibold text-left"
                        >
                          {val.apikey}
                        </label>
                        <div className="relative mt-2">
                          <Field
                            id={val.apikey}
                            name={val.apikey}
                            type={val.type}
                            placeholder={val.placeholder}
                            className={` px-5 shadow-sm  text-sm h-14 outline-none w-full  rounded-sm
                             border   border-gray-400 
                            `}
                          />
                        </div>
                      </div>
                      {touched[val.apikey] && errors[val.apikey] && (
                        <div className="mt-2 text-red-400 text-sm px-2 py-1 rounded-sm">
                          {errors[val.apikey]}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>

              <div className="">
                <button
                  className="bg-[#833cdc] hover:bg-opacity-90 w-fit   transition-all
                       ease-in-out duration-300 px-7 rounded-md py-2 rounded-tablet text-white font-semibold"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="">
        <AdminsInfo infodata={infodata} />
      </div>
    </div>
  );
}

export default CreateAdminAccount;
