import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import image from "../../Resources/Images/bitBgRemove.png";
import axios from "../../HOC/Axios/Axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function ViewSingleUser() {
  const params = useParams();
  const navigate = useNavigate();
  // console.log("iddddd", id);
  const [datas, setDatas] = useState([]);
  useEffect(() => {
    if (params) {
      getSingleUserID();
    }
  }, [params]);
  const getSingleUserID = () => {
    axios
      .get(
        `/user/${params.id}?bankwithdraw=true&usdtwithdraw=true&deposit=true&amount=true&trade=true`
      )
      .then((res) => {
        setDatas(res?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getSingleUserID();
  }, []);

  return (
    <div>
      <div className="text-base font-medium  flex justify-end  text-[#686868] ">
        <button
          className="bg-GrayLight border  absolute top-24 m-2 w-fit px-4 py-1.5 rounded-sm  capitalize "
          onClick={() => navigate(-1)}
        >
          back
        </button>
      </div>
      <div className="bg-secondMainColor w-full h-40"></div>
      <div className="px-5">
        <div className="w-[97%]  py-5 relative mx-auto border  flex justify-between rounded-xl -mt-16 bg-white">
          <div className="flex justify-between  w-7/12">
            <div className="absolute -top-10 left-5 bg-white p-1 rounded-xl">
              <div className=" rounded-md  bg-gray-200 w-32 h-32">
                {datas?.profile ? (
                  <img
                    src={datas?.profile}
                    alt="Loading"
                    className="w-32 h-32 "
                  />
                ) : (
                  <div className="w-32 lex justify-center items-center h-32">
                    <Skeleton />
                  </div>
                )}
              </div>
            </div>
            <div className="w-[70%]  ml-auto">
              <div className="">
                <div className="bg-[#fef1ff] mb-2 border border-[#f8e2f9] w-fit px-5 capitalize text-xs py-0.5 rounded-full text-secondMainColor ">
                  {datas?.country || (
                    <div className="w-64">
                      <Skeleton />
                    </div>
                  )}
                </div>
                <div className="capitalize font-bold text-xl my-1 text-gray-700">
                  {datas?.fullName || (
                    <div className="w-64">
                      <Skeleton />
                    </div>
                  )}
                </div>
                <div className="text-[12.5px] font-medium text-gray-500  flex">
                  <div className="border-r capitalize border-gray-300 pr-1">
                    informations
                  </div>
                  <div className="pl-1">
                    {datas?.email || (
                      <div className="w-64">
                        <Skeleton />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col font-medium border-l-2 px-12">
            <div className="font-bold capitalize text-[15px] my-2">Details</div>
            <div className="text-sm  text-gray-500">
              this profile was created on
              <span className="text-gray-700 font-bold  capitalize px-1">
                {datas?.createdAt ? (
                  new Date(datas?.createdAt).toDateString()
                ) : (
                  <div className="w-64">
                    <Skeleton />
                  </div>
                )}
              </span>
            </div>
            <div className="text-gray-500 text-sm my-1">
              You are currently on the
              <span className="text-gray-700  font-bold  capitalize px-1">
                single user View page
              </span>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 w-[97%]  gap-4 mx-auto my-4">
          <div className=" border w-full rounded-lg col-span-5">
            <div className="py-6 bg-mainColor capitalize font-bold text-gray-600 px-4 rounded-t-xl">
              contact information
            </div>
            <div className="py-4  flex items-center justify-between  capitalize border-b w-10/12 mx-auto">
              <div className="text-gray-500 font-medium"> name </div>
              <div className="text-sm font-bold  ">
                {datas?.fullName || (
                  <div className="w-64">
                    <Skeleton />
                  </div>
                )}
              </div>
            </div>
            <div className="py-4  flex items-center justify-between   border-b w-10/12 mx-auto">
              <div className="text-gray-500 font-medium capitalize">email</div>
              <div className="text-sm font-bold ">
                {datas?.email || (
                  <div className="w-64">
                    <Skeleton />
                  </div>
                )}
              </div>
            </div>{" "}
            <div className="py-4  flex items-center justify-between  capitalize border-b w-10/12 mx-auto">
              <div className="text-gray-500 font-medium"> phone number </div>
              <div className="text-sm font-bold ">
                {datas?.phoneNo || (
                  <div className="w-64">
                    <Skeleton />
                  </div>
                )}
              </div>
            </div>{" "}
            <div className="py-4  flex items-center justify-between  capitalize border-b w-10/12 mx-auto">
              <div className="text-gray-500 font-medium"> address </div>
              <div className="text-sm font-bold ">
                {datas?.address || (
                  <div className="w-64">
                    <Skeleton />
                  </div>
                )}
              </div>
            </div>{" "}
            <div className="py-4  flex items-center justify-between  capitalize border-b w-10/12 mx-auto">
              <div className="text-gray-500 font-medium"> country </div>
              <div className="text-sm font-bold ">
                {datas?.country || (
                  <div className="w-64">
                    <Skeleton />
                  </div>
                )}
              </div>
            </div>{" "}
          </div>
          <div className="border w-full  rounded-lg col-span-7">
            <div className="py-6 bg-mainColor capitalize font-bold text-gray-600 px-4 rounded-t-lg">
              Trade Information
            </div>
            <div className="py-4  flex items-center justify-between  capitalize border-b w-11/12 mx-auto">
              <div className="text-gray-500 font-medium"> total balance </div>
              <div className="text-sm font-bold ">
                {datas?.amount?.balance
                  ? `$ ${Number(datas?.amount?.balance).toFixed(2)}`
                  : null || (
                      <div className="w-64">
                        <Skeleton />
                      </div>
                    )}
              </div>
            </div>{" "}
            <div className="py-4  flex items-center justify-between  capitalize border-b w-11/12 mx-auto">
              <div className="text-gray-500 font-medium"> total trade </div>
              <div className="text-sm font-bold ">
                {datas.trade
                  ? datas.trade.length
                  : null || (
                      <div className="w-64">
                        <Skeleton />
                      </div>
                    )}
              </div>
            </div>{" "}
            <div className="py-4  flex items-center justify-between  capitalize border-b w-11/12 mx-auto">
              <div className="text-gray-500 font-medium"> total withdraw </div>
              <div className="text-sm font-bold ">
                {datas.withdrawal
                  ? datas.withdrawal.length
                  : null || (
                      <div className="w-64">
                        <Skeleton />
                      </div>
                    )}
              </div>
            </div>
            <div className="py-4  flex items-center justify-between  capitalize border-b w-11/12 mx-auto">
              <div className="text-gray-500 font-medium"> total deposit </div>
              <div className="text-sm font-bold ">
                {datas.deposit
                  ? datas.deposit.length
                  : null || (
                      <div className="w-64">
                        <Skeleton />
                      </div>
                    )}
              </div>
            </div>
            <div className="py-4  flex items-center justify-between  capitalize border-b w-11/12 mx-auto">
              <div className="text-gray-500 font-medium"> credit score </div>
              <div className="text-sm font-bold ">
                {" "}
                {datas?.creditScore
                  ? datas?.creditScore
                  : null || (
                      <div className="w-64">
                        <Skeleton />
                      </div>
                    )}
              </div>
            </div>
          </div>
        </div>
        <div className=" px-8 mt-10 ">
          <div className="  capitalize font-bold text-gray-600 my-5">
            legal documents
          </div>

          <div className="grid grid-cols-2 gap-6">
            <div className="border col-span-1">
              {datas.validId ? (
                <img
                  src={datas.validId}
                  alt=""
                  className="w-full h-72 object-cover"
                />
              ) : (
                <div className="w-full">
                  <Skeleton />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewSingleUser;
