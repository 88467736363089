import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import axios from "../../api/axios";
import * as yup from "yup";
// import img from "../../../Resources/Logo.png";
import ContextApi from "../../HOC/ContextApi/ContextApi";
function Contact() {
  const [storeValues, setStoreValues] = useState([]);
  const buyTime = [
    { id: 1, option: "1" },
    { id: 2, option: "2" },
    { id: 3, option: "3" },
  ];
  let buyTimeOpt = [];
  buyTime.map((item, index) => {
    return buyTimeOpt.push(item);
  });

  const contactForm = [
    {
      label: "title",
      type: "text",
      apikey: "title",
      placeholder: "title",
      // min: "0",
    },
    {
      label: "address",
      type: "text",
      apikey: "address",
      placeholder: "address",
      // min: "0",
    },
    {
      label: "title2",
      type: "text",
      apikey: "title2",
      placeholder: "title2",
      // min: "0",
    },
    {
      label: "contact number",
      type: "number",
      apikey: "contact",
      //   min: "1",
      placeholder: "contact number",
    },
    {
      label: "email",
      type: "email",
      apikey: "email",
      placeholder: "email",
      // options: [{ id: 0, option: "select ", apikey: "" }, ...buyTimeOpt],
    },
  ];

  const contactSchema = yup.object().shape({
    title: yup.string().required("required"),
    address: yup.string().required("required"),
    title2: yup.string().required("required"),
    contact: yup
      .string()
      .min(2, "Too Short!")
      .max(10, "Too Long!")
      .required("required"),
    email: yup.string().email("invalid").required("required"),
  });

  return (
    <ContextApi>
      <div className="w-full text- [#303132]  relative">
        <Formik
          initialValues={{
            title: storeValues.title
              ? storeValues.title
              : "Head Office & Branch :",
            address: storeValues.address
              ? storeValues.address
              : "sukhanagar-13 Butwal, Rupandehi Nepal",
            title2: storeValues.title2 ? storeValues.title2 : "Info Support :",
            contact: storeValues.contact ? storeValues.concat : "9876567976",
            email: storeValues.email ? storeValues.email : "admim@gmail.com",
          }}
          enableReinitialize
          onSubmit={async (values, { resetForm }) => {
            console.log(values);
            setStoreValues(values);
            //   console.log(storeBuyData);
            // values.storeSellData

            // try {
            //   setIsLoading(true);
            //   axios
            //     .post("/buy", values)
            //     .then((res) => {
            //       console.log(res);
            //       if (res.status === 200) {
            //         toast.success("Data has been sent sucessfully");
            //         setShow(false);
            //         setIsLoading(false);
            //         resetForm();
            //       }
            //     })
            //     .catch((err) => {
            //       console.log(err);
            //       setIsLoading(false);
            //     });
            // } catch (error) {
            //   console.log(error);
            // }
          }}
          validationSchema={contactSchema}
        >
          {({ handleSubmit, errors, touched }) => {
            return (
              <Form className="  w-[95%]   rounded-[5px] mx-auto">
                <ToastContainer autoClose={1000} />
                <div className="text-xl font-medium  pt-6 text-[#686868] ">
                  {" "}
                  Welcome to Admin Dashboard!!
                </div>
                <div className="  gap-x-7 mt-5 gap-y-10 grid grid-cols-2  py-3 pb-10 ">
                  {contactForm.map((item, index) => {
                    if (item.type === "select") {
                      return (
                        <div className="    relative " key={index}>
                          <div className="absolute  -top-3 left-3  px-2 ">
                            <label className="font-medium text-[13px]  mx-auto  px-3  text-[#9b9b9b] bg-[#F7FAFC]  capitalize">
                              {item.label}
                            </label>
                          </div>
                          <div
                            className=" border border-[#c8c8c8]  "
                            key={index}
                          >
                            <Field
                              className="h-14   outline-none  px-5 bg-[white] w-full   
                                text-sm placeholder:text-xs sm:placeholder:text-sm focus:outline-primary"
                              as={item.type}
                              name={item.apikey}
                              placeholder={item.placeholder}
                            >
                              {" "}
                              {item.options?.map((opt, i) => {
                                return (
                                  <option
                                    id={opt.apikey}
                                    value={opt.apikey}
                                    key={i}
                                  >
                                    {opt.option}
                                  </option>
                                );
                              })}
                            </Field>
                          </div>

                          {errors[item.apikey] && touched[item.apikey] ? (
                            <div className="text-red-500 text-sm mt-1 capitalize px-2">
                              {errors[item.apikey]}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    } else {
                      return (
                        <div className="   relative " key={index}>
                          <div className="absolute  -top-3 left-3  px-2 ">
                            <label className="font-medium text-[13px] bg-[white] mx-auto  px-3  text-[#9b9b9b]   capitalize">
                              {item.label}
                            </label>
                          </div>
                          <div
                            className=" border border-[#ababab] "
                            key={index}
                          >
                            <Field
                              className="h-14 bg-[white]  outline-none  px-5  w-full   
                                  text-sm placeholder:text-xs sm:placeholder:text-sm focus:outline-primary"
                              type={item.type}
                              name={item.apikey}
                              min={item.min ? item.min : null}
                              // onChange={(e) =>
                              //   setStoreBuyData(e.target.value)
                              // }
                              placeholder={item.placeholder}
                            />
                          </div>

                          {errors[item.apikey] && touched[item.apikey] ? (
                            <div className="text-red-500 text-sm mt-1 capitalize px-2">
                              {errors[item.apikey]}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="flex flex-col  items-end  relative">
                  {/* {Images ? ( */}
                  <button
                    className={`px-[47px] py-[10px] bg-[#833CDC] flex items-center gap-1 rounded-md text-white `}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </ContextApi>
  );
}

export default Contact;
