import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoClose } from "react-icons/io5";
import axios from "../../HOC/Axios/Axios";
// import Spinner from "../../components/pageComponents/Spinner/Spinner";
import Loader from "../../components/pageComponents/Spinner/loader";
import ScrollOff from "../../components/pageComponents/WindowScrollOff/ScrollOff";

function MasterFormModel({
  setClickReject,
  title,
  Id,
  status,
  getUserData,
  userId,
  tradeIds,
  setTargetedValue,
  message,
}) {
  const [spinner, setSpinner] = useState(false);

  const profitLoss = [
    { id: 1, option: "profit" },
    { id: 2, option: "loss" },
    { id: 3, option: "N/A" },
  ];
  let profitLossOpt = [];
  profitLoss.map((item, index) => {
    return profitLossOpt.push(item);
  });
  console.log(title);
  const rejectionForm = [
    {
      label: "status",
      type: "text",
      apikey: "status",
      placeholder: "status",
      status: status,
    },
    {
      label: "Rejection Description",
      type: "text",
      apikey: "statusReason",
      placeholder: "description",
    },
  ];

  const profitAndLoss = [
    {
      label: "profit/loss",
      type: "select",
      apikey: "pl",
      options: [{ id: 0, option: "select ", apikey: "" }, ...profitLossOpt],
    },
    {
      label: "rate",
      type: "number",
      apikey: "rate",
      min: "0",
      placeholder: "rate",
    },
  ];
  const rejectionSchema = yup.object().shape({
    statusReason: yup.string().required("required"),
    status: yup.string().required("required"),
  });
  const profitLossSchema = yup.object().shape({
    pl: yup.string().required("required"),
  });

  const updateBankDepositStatus = (values, resetForm) => {
    console.log(Id);
    console.log(values);
    setSpinner(true);
    axios
      .patch(`/deposit/changeBankDepositStatus/${Id}`, values)
      .then((res) => {
        if (res.status === 200) {
          toast.info("Updated status sucessfully");
          setTimeout(() => {
            setClickReject(false);
            setSpinner(false);
            getUserData();
          }, 900);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateUSDTDepositStatus = (values, resetForm) => {
    console.log(values);
    setSpinner(true);
    axios
      .patch(`/deposit/updateQrDepositStatus/${Id}`, values)
      .then((res) => {
        if (res.status === 200) {
          toast.info("Updated status sucessfully");
          setTimeout(() => {
            setClickReject(false);
            setSpinner(false);
            getUserData();
          }, 900);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateBankWithdrawStatus = (values, resetForm) => {
    values.userId = userId;
    console.log(values);
    setSpinner(true);
    axios
      .patch(`/withdrawal/updateBankStatus/${Id}`, values)
      .then((res) => {
        if (res.status === 200) {
          toast.info("Updated status sucessfully");
          setTimeout(() => {
            setClickReject(false);
            setSpinner(false);
            getUserData();
          }, 900);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateUSDTWithdrawStatus = (values, resetForm) => {
    console.log(values);
    setSpinner(true);
    axios
      .patch(`/withdrawal/updateUSDTStatus/${Id}`, values)
      .then((res) => {
        if (res.status === 200) {
          setSpinner(false);
          toast.info("Updated status sucessfully");
          setTimeout(() => {
            setClickReject(false);
            getUserData();
          }, 900);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const SubmitTradeProfitLoss = (values, resetForm) => {
    let TradeIds = tradeIds.filter((val) => {
      return val !== null && val;
    });
    values.rate = values.rate.toString();
    values.tradeIds = JSON.stringify(TradeIds);
    values.timestamp = Id.toString();

    setSpinner(true);
    axios
      .post("/trade/profitLoss", values)
      .then((res) => {
        if (res.status === 201) {
          // toast.success(res.data.message);
          toast.success("Trade has been done successfully");
          setTimeout(() => {
            setSpinner(false);
            getUserData();
            setTargetedValue("");
            setClickReject(false);
            resetForm();
          }, 900);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        setSpinner(false);
      });
  };

  return (
    <ScrollOff message={message}>
      <div className="fixed  h-full top-0 left-0 w-full z-30  flex items-center justify-center bg-black   bg-opacity-20">
        {spinner && <Loader />}
        <div className=" bg-mainColor  w-[95%] laptop:w-7/12 h-fit py-7 mx-auto text-[#303132]  relative">
          {title === "percentage" ? (
            <Formik
              initialValues={{
                pl: "",
                rate: "",
              }}
              onSubmit={async (values, { resetForm }) => {
                SubmitTradeProfitLoss(values, resetForm);
              }}
              validationSchema={profitLossSchema}
            >
              {({ errors, touched }) => {
                return (
                  <Form className="  w-[95%]   rounded-[5px] mx-auto">
                    <ToastContainer autoClose={1000} />
                    <div
                      className="border-2 rounded-full text-2xl p-1 cursor-pointer 
            hover:bg-gray-400 hover:text-white hover:scale-105  ml-auto w-fit"
                      onClick={() => setClickReject(false)}
                    >
                      <IoClose />
                    </div>
                    <div className="text-xl font-medium  capitalize  text-[#ff4747] ">
                      {" "}
                      profit and Loss percentage
                    </div>
                    <div className="  gap-x-7 mt-5 gap-y-10 grid grid-cols-1  py-3 pb-10 ">
                      {profitAndLoss.map((item, index) => {
                        if (item.type === "select") {
                          return (
                            <div className="    relative " key={index}>
                              <div className="absolute  -top-3 left-3  px-2 ">
                                <label className="font-medium text-[13px]  mx-auto  px-3  text-[#9b9b9b] bg-mainColor  capitalize">
                                  {item.label}
                                </label>
                              </div>
                              <div
                                className=" border border-[#c8c8c8]  "
                                key={index}
                              >
                                <Field
                                  className="h-14   outline-none  px-5 bg-mainColor w-full   
                            text-sm placeholder:text-xs sm:placeholder:text-sm focus:outline-primary"
                                  as={item.type}
                                  name={item.apikey}
                                  placeholder={item.placeholder}
                                >
                                  {" "}
                                  {item.options?.map((opt, i) => {
                                    return (
                                      <option
                                        id={opt.apikey}
                                        value={opt.apikey}
                                        key={i}
                                      >
                                        {opt.option}
                                      </option>
                                    );
                                  })}
                                </Field>
                              </div>

                              {errors[item.apikey] && touched[item.apikey] ? (
                                <div className="text-red-500 text-sm mt-1 capitalize px-2">
                                  {errors[item.apikey]}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        } else {
                          return (
                            <div className="   relative " key={index}>
                              <div className="absolute  -top-3 left-3  px-2 ">
                                <label className="font-medium text-[13px] bg-mainColor mx-auto  px-3  text-[#9b9b9b]   capitalize">
                                  {item.label}
                                </label>
                              </div>
                              <div
                                className=" border border-[#ababab] "
                                key={index}
                              >
                                <Field
                                  className="h-14 bg-mainColor  outline-none  px-5  w-full   
                              text-sm placeholder:text-xs sm:placeholder:text-sm focus:outline-primary"
                                  type={item.type}
                                  name={item.apikey}
                                  min={item.min ? item.min : null}
                                  placeholder={item.placeholder}
                                />
                              </div>

                              {errors[item.apikey] && touched[item.apikey] ? (
                                <div className="text-red-500 text-sm mt-1 capitalize px-2">
                                  {errors[item.apikey]}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div className="flex flex-col  items-end  relative">
                      <button
                        className={`px-[47px] py-[10px] bg-[#833CDC] flex items-center gap-1 rounded-md text-white 
                `}
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <Formik
              initialValues={{
                statusReason: "",
                status: status ? status : "",
                userId: userId ? userId : null,
              }}
              onSubmit={async (values, { resetForm }) => {
                switch (title) {
                  case "deposit rejection by bank":
                    updateBankDepositStatus(values, resetForm);
                    break;

                  case "deposit rejection by usdt":
                    updateUSDTDepositStatus(values, resetForm);
                    break;
                  case "withdraw rejection by bank":
                    updateBankWithdrawStatus(values, resetForm);
                    break;
                  case "withdraw rejection by usdt":
                    updateUSDTWithdrawStatus(values, resetForm);
                    break;

                  default:
                    break;
                }
              }}
              validationSchema={rejectionSchema}
            >
              {({ errors, touched }) => {
                return (
                  <Form className="  w-[95%]   rounded-[5px] mx-auto">
                    <ToastContainer autoClose={1000} />
                    <div
                      className="border-2 rounded-full text-2xl p-1 cursor-pointer 
            hover:bg-gray-400 hover:text-white hover:scale-105  ml-auto w-fit"
                      onClick={() => setClickReject(false)}
                    >
                      <IoClose />
                    </div>
                    <div className="text-xl font-medium  capitalize text-[#ff4747] ">
                      message for rejection!!
                    </div>
                    <div className="  gap-x-7 mt-5 gap-y-10 grid grid-cols-1  py-3 ">
                      {rejectionForm.map((item, index) => {
                        if (item.type === "select") {
                          return (
                            <div className="    relative " key={index}>
                              <div className="absolute  -top-3 left-3  px-2 ">
                                <label className="font-medium text-[13px]  mx-auto  px-3  text-[#9b9b9b] bg-mainColor  capitalize">
                                  {item.label}
                                </label>
                              </div>
                              <div
                                className=" border border-[#c8c8c8]  "
                                key={index}
                              >
                                <Field
                                  className="h-14   outline-none  px-5 bg-mainColor w-full   
                            text-sm placeholder:text-xs sm:placeholder:text-sm focus:outline-primary"
                                  as={item.type}
                                  name={item.apikey}
                                  placeholder={item.placeholder}
                                >
                                  {item.options?.map((opt, i) => {
                                    return (
                                      <option
                                        // id={opt.apikey}
                                        // value={opt.apikey}
                                        key={i}
                                      >
                                        {opt.option}
                                      </option>
                                    );
                                  })}
                                </Field>
                              </div>

                              {errors[item.apikey] && touched[item.apikey] ? (
                                <div className="text-red-500 text-sm mt-1 capitalize px-2">
                                  {errors[item.apikey]}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        } else {
                          return (
                            <div className="   relative " key={index}>
                              <div className="absolute  -top-3 left-3  px-2 ">
                                <label className="font-medium text-[13px] bg-mainColor mx-auto  px-3  text-[#797979]   capitalize">
                                  {item.label}
                                </label>
                              </div>
                              <div
                                className=" border border-[#ababab] "
                                key={index}
                              >
                                {item.status === "rejected" ? (
                                  <div
                                    className=" bg-mainColor  my-5 outline-none  px-5  w-full   
                                text-sm placeholder:text-xs sm:placeholder:text-sm focus:outline-primary"
                                  >
                                    {status}
                                  </div>
                                ) : (
                                  <Field
                                    className="h-14 bg-mainColor  outline-none  px-5  w-full   
                              text-sm placeholder:text-xs sm:placeholder:text-sm focus:outline-primary"
                                    type={item.type}
                                    name={item.apikey}
                                    min={item.min ? item.min : null}
                                    placeholder={item.placeholder}
                                  />
                                )}
                              </div>

                              {errors[item.apikey] && touched[item.apikey] ? (
                                <div className="text-red-500 text-sm mt-1 capitalize px-2">
                                  {errors[item.apikey]}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div className="flex flex-col  items-end  relative">
                      <button
                        className={`px-[47px] py-[10px] bg-[#833CDC] flex items-center gap-1 rounded-md text-white 
                `}
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      </div>
    </ScrollOff>
  );
}

export default MasterFormModel;
