import axios from "axios";
import cookie from "js-cookie";
const instance = axios.create({
	//   baseURL: process.env.REACT_APP_API_URL,
	baseURL: "https://backend.carlylebkexin.online",
	// baseURL: "http://192.168.1.48:4000",

	//   timeout: 1000,
	//   headers: { "X-Custom-Header": "foobar" },
});
// let token1 = cookie.get("adminToken");
// console.log(token1);

instance.interceptors.request.use(
	async (config) => {
		config.headers = {
			Authorization: cookie.get("adminToken")
				? `Bearer ${cookie.get("adminToken")}`
				: "",
		};
		return config;
	},
	(error) => Promise.reject(error)
);
axios.defaults.headers.post["Authorization"] = `Bearer + ${cookie.get(
	"adminToken"
)}`;

instance.defaults.headers.common["Content-Type"] = "application/json";

export default instance;
