import React, { useState, useEffect } from "react";
import Table from "../../../UI/Table";
import TableFilter from "../../../components/pageComponents/TableFilter/TableFilter";
import ImageModel from "../../../UI/ImageModel/ImageModel";
import axios from "../../../HOC/Axios/Axios";
// import image from "../../../Resources/Images/bitBgRemove.png";
import { BsFillInfoCircleFill } from "react-icons/bs";
// import MasterDeleteModal from "../../../UI/MasterDeleteModal/MasterDeleteModal";
import { PiProhibitInsetFill } from "react-icons/pi";
import { FaHandshake } from "react-icons/fa6";
import Loader from "../Spinner/loader";
import { TiTick } from "react-icons/ti";

function WithdrawBankTable() {
  // const [modelClick, setModelClick] = useState(false);
  const [filteredData, setFilteredData] = useState("");
  const [showData, setShowData] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [counter, setCounter] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [pagination] = useState({
    start: 0,
    end: 10,
  });

  const EndValue = counter * pagination.end;
  const StartValue = EndValue - pagination.end;
  const Thead = [
    { title: "s.nO", with: "10%" },
    { title: "name", with: "10%" },
    { title: "bank", with: "10%" },
    { title: "accountHolderName", with: "20%" },
    { title: "accountNumber", with: "20%" },
    { title: "withdraw amount", with: "20%" },
    { title: "status", with: "10%" },
    // { title: "action", with: "20%" },
  ];

  const HandleSearchInputChange = (e) => {
    // console.log(e.target.value);
    setFilteredData(e.target.value);
  };

  const Filter = (options) => {
    return options.filter((option, index) => {
      return (
        option?.user?.fullName
          .toLowerCase()
          .indexOf(filteredData.toLowerCase()) > -1 ||
        option["accountHolderName"]
          .toLowerCase()
          .indexOf(filteredData.toLowerCase()) > -1
      );
    });
  };
  // const Conformation = (id, userId) => {
  //   setMessage(true);
  //   setStoreUserId(userId);
  //   setStoreAcceptID(id);
  //   // console.log(id);
  // };

  // let ConformationMessage;
  // if (message) {
  //   ConformationMessage = (
  //     <MasterDeleteModal
  //       content={"confirmation message to accept?"}
  //       // id={idStore}
  //       Submit={Submit}
  //       setMessage={setMessage}
  //     />
  //   );
  // }
  const getUserData = () => {
    setShowLoader(true);
    axios
      .get(`/withdrawal`)
      .then((res) => {
        console.log(res.data.data);
        setTableData(res?.data?.data);
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };
  useEffect(() => {
    getUserData();
  }, []);
  let settledData = tableData.filter((val) => {
    return val.status !== "pending";
  });
  return (
    <div>
      {showData && <ImageModel setShowData={setShowData} />}
      {showLoader && <Loader />}
      <div className=" w-full">
        <TableFilter
          HandleSearchInputChange={HandleSearchInputChange}
          Filter={Filter}
          placeholder={"Search by name or account holder name..."}
        />
      </div>
      {settledData.length > 0 ? (
        <div className="overflow-x-auto">
          {Filter(settledData).length > 0 ? (
            <Table
              Head={Thead}
              counter={counter}
              setCounter={setCounter}
              data={settledData}
              paginationEnd={pagination.end}
            >
              {Filter(settledData)
                .slice(StartValue, EndValue)
                .map((val, i) => {
                  return (
                    <tr
                      key={i}
                      width={val.width}
                      className="border-b text-sm capitalize hover:bg-white text-center"
                    >
                      <td className="px-3 py-7 ">{StartValue + 1 + i}</td>
                      <td className="px-3 py-6 text-[15px] font-bold">
                        {val?.user?.fullName}
                      </td>
                      <td className="px-3   capitalize">{val?.bankName}</td>
                      <td className="px-3">{val?.accountHolderName}</td>
                      <td className="px-1">{val?.accountNo}</td>
                      <td className="px-1">{val?.withdrawalAmount}</td>

                      <td className="px-3">
                        {val.status === "settled" ? (
                          <div className=" flex gap-4 justify-center items-center">
                            <FaHandshake className="text-4xl text-secondMainColor " />{" "}
                            <BsFillInfoCircleFill
                              className="text-sm cursor-pointer text-gray-400"
                              // onClick={() => showAllData()}
                            />
                          </div>
                        ) : val.status === "rejected" ? (
                          <div className="  flex  gap-4 justify-center items-center">
                            <PiProhibitInsetFill className="-rotate-45 text-4xl text-red-600 " />{" "}
                          </div>
                        ) : val.status === "completed" ? (
                          <div className="  flex  gap-4 justify-center items-center">
                            <TiTick className="text-4xl text-green-600 " />{" "}
                          </div>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
            </Table>
          ) : (
            <div className="text-left px-8 py-3">
              <p className="text-gray-500 font-medium">No data found......</p>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Table Head={Thead}></Table>
          <div className=" flex justify-center items-center h-[400px] capitalize text-gray-600 font-semibold text-xl">
            {!showLoader && `no data available yet`}
          </div>
        </div>
      )}
    </div>
  );
}

export default WithdrawBankTable;
