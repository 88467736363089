import React from "react";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
function Table({
  Head,
  children,
  counter,
  setCounter,
  data,
  paginationEnd,
  title,
  status,
}) {
  // const handlePage = (i) => {
  //   setCounter(i);
  // };

  // console.log(data.status);
  // console.log(data);
  const lengthDivide = Math.ceil(data?.length / paginationEnd);
  // console.log(lengthDivide);

  const firstPageDirectly = () => {
    setCounter(1);
  };
  const prevPage = () => {
    setCounter(counter > 1 ? counter - 1 : 1);
  };

  const nextPage = () => {
    setCounter(counter < lengthDivide ? counter + 1 : lengthDivide);
  };
  const lastPageDirectly = () => {
    setCounter(lengthDivide);
  };
  // const handleSelectValue = (e) => {
  //   console.log(e.target.value);
  // };

  // Calculate the range of page numbers to display based on the current page
  const startPage = Math.max(1, counter - 2);
  const endPage = Math.min(lengthDivide, counter + 2);
  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }
  return (
    <div
      className={`w-full h-full  sticky top-0   ${
        title === "user details" ? "pb-12" : ""
      }`}
    >
      <div
        className={` w-[95vw] mx-2 laptop:w-[95%]  laptop:mx-auto   my-3    `}
      >
        <table className={`w-full   border  `} cellPadding={0} cellSpacing={0}>
          <thead className="  top-0  ">
            <tr className={`  laptop:w-full border-b  `}>
              {Head?.map((val, i) => {
                return (
                  <td
                    key={i}
                    style={{ width: val.width }}
                    className={`capitalize text-[#4F4F4F] text-center  px-2 ${
                      title === "adminInfoTable"
                        ? " py-3 tablet:py-[16px]  "
                        : "  py-5"
                    } 
                    text-lft  text-sm  tablet:font-semibold`}
                  >
                    {val.title}
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody className="bg-[#f6fbff] ">{children}</tbody>
        </table>
        {lengthDivide > 1 && (
          <div className="flex gap-5 px-8 mt-8 items-center justify-end ">
            <div className="text-gray-500">
              page {counter} of {lengthDivide}
            </div>
            <div className="flex items-center ">
              <button
                className={` border border-secondMainColor text-xl text-secondMainColor ${
                  counter === 1 ? " cursor-not-allowed" : ""
                } `}
                onClick={() => firstPageDirectly()}
              >
                <MdKeyboardDoubleArrowLeft />
              </button>
            </div>
            <div className="flex items-center ">
              <button
                className={` border border-secondMainColor text-xl text-secondMainColor ${
                  counter === 1 ? " cursor-not-allowed" : ""
                }`}
                onClick={() => prevPage()}
              >
                <MdKeyboardArrowLeft />
              </button>
            </div>
            <div className="flex gap-3 items-center">
              {pageNumbers.map((page, index) => (
                <div key={index}>
                  <button
                    className={`text-sm border  w-6 h-6 text-secondMainColor ${
                      counter === page
                        ? "bg-secondMainColor text-white"
                        : " border-secondMainColor"
                    }`}
                    onClick={() => setCounter(page)}
                  >
                    {page}
                  </button>
                </div>
              ))}
            </div>
            <div className="flex items-center ">
              <button
                className={`${
                  counter === lengthDivide ? " cursor-not-allowed" : ""
                } text-xl border border-secondMainColor text-secondMainColor  `}
                onClick={() => nextPage()}
              >
                <MdKeyboardArrowRight />
              </button>
            </div>
            <div className="flex items-center ">
              <button
                className={` ${
                  counter === lengthDivide ? " cursor-not-allowed" : ""
                } text-xl border border-secondMainColor text-secondMainColor  `}
                onClick={() => lastPageDirectly()}
              >
                <MdKeyboardDoubleArrowRight />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Table;
