import React, { useEffect } from "react";
// import logoImage from "../../../Resources/Images/bitBgRemove.png";
import Navitems from "../NavItems/Nav_items";
import { BiLogOut } from "react-icons/bi";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
// import Loader from "../../pageComponents/Spinner/loader";
// import { IoCreate } from "react-icons/io5";
// import { PiCarProfileBold } from "react-icons/pi";
import { FaUserSecret } from "react-icons/fa";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
function Sidebar({
  setShowSidebar,
  showSidebar,
  setShowCancelButton,
  show,
  setShow,
}) {
  const navigate = useNavigate();
  useEffect(() => {
    if (show === true) {
      let cancelSlowlyAppear = setTimeout(() => {
        setShowCancelButton(true);
      }, 900);
      return () => clearTimeout(cancelSlowlyAppear);
    }
  }, [show]);

  return (
    <div
      className={` bg-white sticky scroll  ${
        showSidebar ? "tablet:h-full " : "tablet:h-[873px]   laptopM:h-[815px] "
      } left-0 top-0 z-50 border rounded-md h-full    `}
    >
      <div className=" hidden  laptopM:block">
        {showSidebar ? (
          <div className="cursor-pointer m-4  bg-mainColor flex items-center justify-center rounded-full border w-10 h-10 relative -left-1.5 z-40">
            <MdKeyboardArrowRight
              onClick={() => setShowSidebar(false)}
              className=" text-3xl "
            />
          </div>
        ) : (
          <div className="cursor-pointer m-4 flex items-center bg-white justify-center rounded-full border w-10 h-10 absolute left-[185px] z-40">
            <MdKeyboardArrowLeft
              onClick={() => setShowSidebar(true)}
              className="text-3xl   "
            />
          </div>
        )}
      </div>
      <div className=" flex  flex-col justify-between pb-5  w-full  h-full ">
        <div>
          <div>
            <div className={`w-full   `}>
              <div
                className={` w-full ${
                  show || !showSidebar ? "" : "hidden"
                } bg-mainColor`}
              >
                <svg viewBox={`0 0 250 80 `}>
                  <text df="5em" x="10%" y="60%" className="font-bold">
                    uni trading
                  </text>
                </svg>
              </div>
            </div>

            <hr
              className={`w-[97%]  mx-auto  ${
                showSidebar ? "block tablet:hidden" : "mt-2.5"
              }`}
            />
          </div>
          <div className={`${showSidebar ? "" : " pt-2 tablet:pt-8  "}`}>
            <Navitems
              show={show}
              setShow={setShow}
              showSidebar={showSidebar}
              setShowSidebar={setShowSidebar}
              setShowCancelButton={setShowCancelButton}
            />
          </div>
          {/* {Cookies.get("role") === "superAdmin" && ( */}
          <div
            className="tablet:mb-3.5 px-3  font-semibold   cursor-pointer rounded-md  capitalize
     flex mx-3 gap-2   my-2 relative items-center py-2 border hover:bg-mainColor hover:text-secondMainColor"
            onClick={() => {
              navigate("/create_admin_account");
            }}
          >
            <FaUserSecret className="text-2xl" />
            <span className={`${showSidebar ? "block tablet:hidden" : ""}`}>
              create admin account
            </span>
          </div>
          {/* )} */}
          <div
            className="tablet:mb-3.5 px-3  font-semibold   cursor-pointer rounded-md  capitalize
     flex mx-3 gap-2 laptop:hidden  my-2 relative items-center py-2 border hover:bg-mainColor hover:text-secondMainColor"
            onClick={() => {
              Cookies.remove("adminToken");
              navigate("/login");
            }}
          >
            <BiLogOut className="text-2xl" />
            Logout
          </div>
        </div>
        {/* <div
          className={`text-secondMainColor font-medium  text-sm tracking-wide text-center  w-full  ${
            showSidebar ? "hidden" : "block"
          }`}
        >
          This copyright belongs to
          <div className="mt-[2px]">&copy; dubaiCrypto</div>
        </div> */}
      </div>
    </div>
  );
}

export default Sidebar;
