import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../HOC/Axios/Axios";
import ContextApi from "../../HOC/ContextApi/ContextApi";
// import Spinner from "../../components/pageComponents/Spinner/Spinner";
import { IoClose } from "react-icons/io5";
import image from "../../Resources/Images/dummyImage.svg";
// import Skeleton from "../../UI/Skeleton/Skelleton";
import Loader from "../../components/pageComponents/Spinner/loader";
import ScrollOff from "../../components/pageComponents/WindowScrollOff/ScrollOff";

function DashBoard() {
  const [Images, setImages] = useState("");
  // const [storeTargatedValue, setStoreTargatedValue] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  const buyTime = [
    { id: 1, option: "1" },
    { id: 2, option: "2" },
    { id: 3, option: "3" },
  ];
  let buyTimeOpt = [];
  buyTime.map((item) => {
    return buyTimeOpt.push(item);
  });

  const buyForm = [
    {
      label: "Slogan",
      type: "text",
      apikey: "sologan",
      placeholder: "slogan",
      // min: "0",
    },
    {
      label: "description",
      type: "text",
      apikey: "description",
      placeholder: "description",
      // min: "0",
    },

    // {
    //   label: "partners",
    //   type: "text",
    //   apikey: "partners",
    //   placeholder: "partners",
    //   // options: [{ id: 0, option: "select ", apikey: "" }, ...buyTimeOpt],
    // },
  ];
  // const buySchema = yup.object().shape({
  //   sologan: yup.string().required("required"),
  // });

  // window ma keypress used for using enter key
  // window.addEventListener("keypress", (e) => {
  //   if (e.key === "Enter") {
  //     setStoreTargatedValue([...storeTargatedValue, targetedValue]);
  //     setTargetedValue("");
  //   }
  // });
  // useEffect(() => {
  //   const handleKeyPress = (e) => {
  //     if (e.key === "Enter" && targetedValue.length > 0) {
  //       setStoreTargatedValue([...storeTargatedValue, targetedValue]);
  //       setTargetedValue("");
  //     }
  //   };

  //   window.addEventListener("keypress", handleKeyPress);

  //   return () => {
  //     window.removeEventListener("keypress", handleKeyPress);
  //   };
  // }, [storeTargatedValue, targetedValue]);
  // const DeleteTableData = (id) => {
  //   storeTargatedValue.splice(id, 1);
  //   setStoreTargatedValue([...storeTargatedValue]);
  // };
  // console.log(targetedValue);
  // const handleAddedData = (e) => {
  //   if (targetedValue.length > 0 || targetedValue.length === "") {
  //     setStoreTargatedValue([...storeTargatedValue, targetedValue]);
  //     setTargetedValue("");
  //   }
  // };
  // const handleChange = (e) => {

  //   setTargetedValue(e.target.value);
  // };

  let loader;
  if (showSpinner) {
    loader = <Loader />;
  }

  const handleImageChange = (e, apikey) => {
    setImages(e.target.files[0]);
  };
  const handleRemoveImage = () => {
    setImages("");
    document.querySelector("#image").value = null;
  };
  const [data, setData] = useState([]);
  const getData = async () => {
    setShowSpinner(true);
    try {
      const response = await axios.get("/company/getCompanyInfo");
      console.log(response);

      if (response.status === 200 && response.data) {
        setData(response.data);
      }
      setShowSpinner(false); // Set showSpinner to false in the try block
    } catch (error) {
      console.error(error.message);
      setShowSpinner(false); // Set showSpinner to false in the catch block
      toast.error(error.message, { toastId: "Error" });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const Submits = (values, { resetForm }) => {
    console.log(Images);
    setShowSpinner(true);
    try {
      const formData = new FormData();
      formData.append("sologan", values.sologan);
      formData.append("description", values.description);
      if (Images) {
        formData.append("logo", Images);
      } else if (data?.length > 0 && data[0]?.logo) {
        formData.append("logo", data[0]?.logo);
      }
      axios
        .post(`/company/postCompanyInfo`, formData)
        .then((res) => {
          console.log(res);
          if (res.status === 201) {
            toast.success(
              res?.data?.message,
              {
                toastId: "Added success",
              },
              setShowSpinner(false)
            );
            // document.body.style.overflow = "auto";
            setImages("");
            // After successful submission, fetch the data again to update the form
            getData();
            resetForm();
          }
        })
        .catch((err) => {
          setShowSpinner(false);
          document.body.style.overflow = "auto";
          toast.error(err?.response?.data?.message);
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  console.log(data);
  return (
    <ContextApi>
      <ScrollOff message={showSpinner}> {showSpinner && loader}</ScrollOff>
      <div className=" px-3 tablet:px-8">
        <div className="w-full">
          <ToastContainer autoClose={1000} />
          <Formik
            initialValues={{
              sologan: data?.length > 0 ? data[0]?.sologan : "",
              description: data[0]?.description ? data[0]?.description : "",
            }}
            onSubmit={Submits}
            enableReinitialize // This enables reinitialization when initialValues change
            // validationSchema={walletSettingSchema}
          >
            {({ errors, touched }) => (
              <Form className="rounded-[5px]  text-left ">
                <div className="tablet:grid grid-cols-2 gap-5">
                  {buyForm.map((val, i) => {
                    return (
                      <div key={i} className="flex  flex-col gap-1 mt-5">
                        <label
                          htmlFor={val.apikey}
                          className="capitalize mb-2 font-semibold text-left"
                        >
                          {val.label}
                        </label>
                        <Field
                          id={val.apikey}
                          name={val.apikey}
                          type="text"
                          placeholder={val.placeholder}
                          // onChange={(e) => handleChange(e)}
                          className="p-4 rounded-[3px] border border-gray-400 bg-white text-sm placeholder:text-xs sm:placeholder:text-sm focus:outline-primary"
                        />
                        {errors[val.apikey] && touched[val.apikey] && (
                          <div className="text-red-500 text-sm mt-1 capitalize px-2">
                            {errors[val.apikey]}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="mb-2 mt-5 text-left">
                  <label htmlFor="image" className="capitalize font-semibold">
                    logo
                  </label>
                </div>
                <div className=" tablet:w-6/12">
                  <input
                    id="image"
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={handleImageChange}
                    className="hidden"
                  />
                  <div className="border w-full overflow-hidden p-1 border-gray-500 border-dotted">
                    {Images ? (
                      <div className="h-[300px] relative bg-white opacity-90 flex justify-center items-center">
                        <img
                          src={URL.createObjectURL(Images)}
                          alt="QR Code"
                          width={100}
                          height={100}
                          className="object-cover h-[300px] w-[400px] object-center"
                        />
                        <div
                          className="text-lg text-white bg-red-600 p-1 w-fit absolute top-3 right-[7%] cursor-pointer rounded-full"
                          onClick={handleRemoveImage}
                        >
                          <IoClose />
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`h-[300px] ${
                          data?.length > 0 ? "bg-gray-100" : "bg-gray-800"
                        } opacity-90 flex justify-center items-center`}
                      >
                        <img
                          src={data[0]?.logo || image}
                          alt="loading..."
                          width={100}
                          height={100}
                          className={`${
                            data[0]?.logo
                              ? "object-cover h-[300px] "
                              : "object-contain h-[150px] "
                          }  opacity-100 w-full  p-1 object-top`}
                        />
                      </div>
                    )}
                  </div>
                  <label htmlFor="image">
                    <div className="bg-blue-700 capitalize hover:bg-opacity-80 cursor-pointer text-center text-white px-4 py-2">
                      change logo
                    </div>
                  </label>

                  <div className="mt-6">
                    <button
                      type="submit"
                      className={`px-[30px]  mt-6 py-[10px] bg-[#833CDC] capitalize flex items-center gap-1 rounded-md text-white`}
                    >
                      {data?.length > 0 ? "Upload" : "Submit"}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ContextApi>
  );
}

export default DashBoard;
