import React, { useState, useEffect } from "react";
import Table from "../../../UI/Table";
import TableFilter from "../../../components/pageComponents/TableFilter/TableFilter";
import ImageModel from "../../../UI/ImageModel/ImageModel";
import { PiProhibitInsetFill } from "react-icons/pi";
// import image from "../../../Resources/Images/bitBgRemove.png";
// import { TiTick } from "react-icons/ti";
import { BsFillInfoCircleFill } from "react-icons/bs";
import axois from "../../../HOC/Axios/Axios";
import Loader from "../Spinner/loader";
import { FaHandshake } from "react-icons/fa6";
import { TiTick } from "react-icons/ti";
function WithdrawUSDTTable() {
  // const [modelClick, setModelClick] = useState(false);
  const [filteredData, setFilteredData] = useState("");
  const [showData, setShowData] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [counter, setCounter] = useState(1);
  const [pagination] = useState({
    start: 0,
    end: 10,
  });

  const EndValue = counter * pagination.end;
  const StartValue = EndValue - pagination.end;
  const Thead = [
    { title: "s.nO", with: "20%" },
    { title: "name", with: "20%" },
    { title: "date", with: "20%" },
    { title: "withdraw amount", with: "20%" },
    { title: "status", with: "20%" },
  ];

  const HandleSearchInputChange = (e) => {
    // console.log(e.target.value);
    setFilteredData(e.target.value);
  };

  const Filter = (options) => {
    console.log(options);
    return options.filter((option, index) => {
      return (
        option?.createdAt.toLowerCase().indexOf(filteredData.toLowerCase()) >
          -1 ||
        option?.user?.fullName
          .toLowerCase()
          .indexOf(filteredData.toLowerCase()) > -1
      );
    });
  };
  const getUserData = () => {
    setShowLoader(true);
    axois
      .get(`/withdrawal/GetAllUSDTdata`)
      .then((res) => {
        // console.log(res.data.data);
        setTableData(res?.data?.data);
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };
  useEffect(() => {
    getUserData();
  }, []);
  let settleData = tableData.filter((val) => {
    return val.status !== "pending";
  });
  // let com = tableData.filter((val) => {
  //   return val.status === "completed";
  // });
  // console.log(com);
  // const data = tableData.filter((val) => {
  //   return val.status === "settled";
  // });
  // console.log(data);
  // const rej = tableData.filter((val) => {
  //   return val.status === "rejected";
  // });
  // console.log(rej);
  return (
    <div>
      {showData && <ImageModel setShowData={setShowData} />}
      {showLoader && <Loader />}
      <div className="  w-full ">
        <TableFilter
          HandleSearchInputChange={HandleSearchInputChange}
          Filter={Filter}
          placeholder={"Search by name..."}
        />
      </div>
      {settleData.length > 0 ? (
        <div className="overflow-x-auto">
          {Filter(settleData).length > 0 ? (
            <Table
              Head={Thead}
              counter={counter}
              setCounter={setCounter}
              data={settleData}
              paginationEnd={pagination.end}
            >
              {Filter(settleData)
                .slice(StartValue, EndValue)
                .map((val, i) => {
                  return (
                    <tr
                      key={i}
                      width={val.width}
                      className="border-b text-center hover:bg-white"
                    >
                      <td className="px-3 py-7 ">{StartValue + 1 + i}</td>
                      <td className="px-3 capitalize">{val?.user?.fullName}</td>
                      <td className="px-1">
                        {new Date(val.createdAt).toDateString()}
                      </td>
                      <td className="px-3 capitalize">
                        {val.withdrawalAmount}
                      </td>
                      {/* <td className="px-3" onClick={() => setModelClick(true)}>
                      <div className="flex justify-center">
                        {" "}
                        <img
                          src={val.recipt}
                          alt="Loading..."
                          width={20}
                          height={20}
                          className="w-16 h-16 rounded-full hover:scale-105 cursor-pointer object-cover overflow-hidden bg-black -900 object-top "
                        />
                      </div>
                      {modelClick && (
                        <ImageModel
                          image={val.image}
                          setModelClick={setModelClick}
                        />
                      )}
                    </td> */}
                      <td className="px-3">
                        {val.status === "settled" ? (
                          <div className=" flex gap-4 justify-center items-center">
                            <FaHandshake className="text-4xl text-secondMainColor " />{" "}
                            <BsFillInfoCircleFill
                              className="text-sm cursor-pointer text-gray-400"
                              // onClick={() => showAllData()}
                            />
                          </div>
                        ) : val.status === "rejected" ? (
                          <div className="  flex  gap-4 justify-center items-center">
                            <PiProhibitInsetFill className="-rotate-45 text-4xl text-red-600 " />{" "}
                            {/* <BsFillInfoCircleFill className="text-sm cursor-pointer text-gray-400" /> */}
                          </div>
                        ) : val.status === "completed" ? (
                          <div className="  flex  gap-4 justify-center items-center">
                            <TiTick className=" cursor-pointer text-4xl text-green-600" />
                          </div>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
            </Table>
          ) : (
            <div className="text-left px-8 py-3">
              <p className="text-gray-500 font-medium">No data found......</p>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Table Head={Thead}></Table>
          <div className=" flex justify-center items-center h-[400px] capitalize text-gray-600 font-semibold text-xl">
            {!showLoader && `no data available yet`}
          </div>
        </div>
      )}
    </div>
  );
}

export default WithdrawUSDTTable;
