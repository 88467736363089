import {
  MdAppSettingsAlt,
  MdDashboard,
  MdDrafts,
  MdOutlinePassword,
} from "react-icons/md";
import { GiBank } from "react-icons/gi";
import { BiCoin, BiMoneyWithdraw } from "react-icons/bi";
import { BsBank2 } from "react-icons/bs";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import { GoReport } from "react-icons/go";
import { IoSettings } from "react-icons/io5";
import { FaHandshake } from "react-icons/fa";
import { LuQrCode } from "react-icons/lu";
import { HiUserGroup } from "react-icons/hi2";

export const NavData = [
  {
    id: 0,
    title: "dashboard",
    path: "/",
    icon: <MdDashboard className="w-6 h-6" />,
  },
  {
    id: 1,
    title: "user Details",
    path: "/user_details",
    icon: <HiUserGroup className="w-6 h-6" />,
  },
  {
    id: 2,
    title: "deposit",
    icon: <GiBank className="w-6 h-6" />,
    icon1: <IoMdArrowDropup />,
    icon2: <IoMdArrowDropdown />,

    subTitle: [
      {
        sub_title: "by bank",
        paths: "/bank_deposit",
        icon: <BsBank2 />,
      },
      {
        sub_title: "by USDT",
        paths: "/usdt_deposit",
        icon: <LuQrCode />,
      },
    ],
  },

  {
    id: 3,
    title: "trading",
    path: "/trading",
    icon: <BiCoin className="w-6 h-6" />,
  },
  {
    id: 4,
    title: "withdrawal",
    icon: <BiMoneyWithdraw className="w-6 h-6" />,
    icon1: <IoMdArrowDropup />,
    icon2: <IoMdArrowDropdown />,
    subTitle: [
      {
        sub_title: "by bank",
        paths: "/bank_withdrawal",
        icon: <BsBank2 />,
      },
      {
        sub_title: "by USDT",
        paths: "/usdt_withdrawal",
        icon: <LuQrCode />,
      },
    ],
  },
  {
    id: 5,
    title: "settle",
    icon: <FaHandshake className="w-6 h-6" />,
    icon1: <IoMdArrowDropup />,
    icon2: <IoMdArrowDropdown />,
    subTitle: [
      {
        sub_title: "by bank",
        paths: "/bank_settle",
        icon: <BsBank2 />,
      },
      {
        sub_title: "by USDT",
        paths: "/usdt_settle",
        icon: <LuQrCode />,
      },
    ],
  },

  {
    id: 6,
    title: "report",
    icon: <MdDrafts className="w-6 h-6" />,
    icon1: <IoMdArrowDropup />,
    icon2: <IoMdArrowDropdown />,
    subTitle: [
      {
        sub_title: "deposit report",
        paths: "/deposit_report",
        icon: <GoReport />,
      },
      {
        sub_title: "withdrawal report",
        paths: "/withdrawal_report",
        icon: <GoReport />,
      },
      {
        sub_title: "transaction report",
        paths: "/transaction_report",
        icon: <GoReport />,
      },
    ],
  },
  {
    id: 7,
    title: "setting",
    icon: <IoSettings className="w-6 h-6" />,
    // path: "/setting",
    icon1: <IoMdArrowDropup />,
    icon2: <IoMdArrowDropdown />,
    subTitle: [
      // {
      //   sub_title: "contact",
      //   paths: "/contact",
      //   icon: <BiPhone />,
      // },
      {
        sub_title: "wallet setting",
        paths: "/wallet_setting",
        icon: <MdAppSettingsAlt />,
      },
      {
        sub_title: "update password",
        paths: "/update_password",
        icon: <MdOutlinePassword />,
      },
    ],
  },
];
