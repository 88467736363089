import React, { useState, useEffect } from "react";
import Table from "../../UI/Table";
import ContextApi from "../../HOC/ContextApi/ContextApi";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MasterFormModel from "../../UI/RejectionFormModel/RejectionFormModel";
import axios from "../../HOC/Axios/Axios";
import Loader from "../../components/pageComponents/Spinner/loader";
function CoinBuyData() {
  const [counter, setCounter] = useState(1);
  const [showModel, setShowModel] = useState(false);
  const [tableBody, setTableBody] = useState([]);
  const [pagination] = useState({
    start: 0,
    end: 10,
  });
  // pagination logic
  const EndValue = counter * pagination.end;
  const StartValue = EndValue - pagination.end;
  const [targetedValue, setTargetedValue] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [tableRowIds, setTableRowIds] = useState("");

  const Thead = [
    { title: "s.nO", with: "20%" },
    { title: "userName", with: "20%" },
    { title: "coin", with: "20%" },
    { title: "amount", with: "20%" },
    { title: "time", with: "20%" },
  ];

  const Filter = (options) => {
    return options.filter((option) => {
      return option["timestamp"].indexOf(targetedValue) > -1;
    });
  };

  const radioButtons = [
    {
      label: "1 min",
      type: "radio",
      apikey: "picked",
      value: "1",
    },
    {
      label: "2 min",
      type: "radio",
      apikey: "picked",
      value: "2",
    },
    {
      label: "3 min",
      type: "radio",
      apikey: "picked",
      value: "3",
    },
  ];
  const [Id, setId] = useState("");
  const HandleRadioChange = (e, id) => {
    // console.log(e.target.parentElement.parentElement.childNodes[1]);
    if (e.target.parentElement.parentElement.childNodes[1]) {
      setTargetedValue(
        e.target.parentElement.parentElement.childNodes[1].value
      );
      setId(id + 1);
    }
  };

  const handleClear = () => {
    setTargetedValue("");
  };

  let storeId = [];
  tableBody.map((val) => {
    return storeId.push(targetedValue === val.timestamp ? val?.id : null);
  });

  const handleNext = (e, id) => {
    e.preventDefault();

    setShowModel(true);
  };
  const getTradeData = () => {
    setShowLoader(true);
    axios
      .get("/trade/getAllTrade")
      .then((res) => {
        setTableBody(res?.data?.trade);
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };
  useEffect(() => {
    getTradeData();
  }, []);
  useEffect(() => {
    const timestamps = tableBody
      .filter((val) => val?.tradestatus === "open")
      .map((val) => val?.timestamp);
    setTableRowIds(timestamps);
    console.log(tableRowIds);
  }, [tableBody]);
  const filteredData = tableBody.filter((val) => val?.tradestatus === "open");
  radioButtons.map((val) => val?.value === targetedValue);
  return (
    <ContextApi>
      {showLoader && <Loader />}
      <ToastContainer autoClose={1000} />

      {tableBody.length > 0 && filteredData.length > 0 ? (
        <div className="w-[95%]   rounded-[5px] mx-auto">
          <div className=" ">
            <div className=" py-4 w-fit  laptop:ml-auto ">
              <div
                className="flex items-center w-fit gap-3 laptop:gap-10 ml-auto border  py-3 px-4 laptop:px-8 laptop:mx-8 rounded-md
           bg-secondMainColor text-mainColor"
              >
                <span className=" font-medium laptop:font-extrabold">
                  Time:
                </span>
                {radioButtons.map((btn, index) => {
                  return (
                    <div key={index} className="flex items-center gap-3 ">
                      <div className="flex items-center gap-3">
                        <label
                          htmlFor={"radio"}
                          className=" font-normal laptop:font-semibold cursor-pointer"
                        >
                          {btn.label}
                        </label>
                        <div>
                          <div className="bg-white rounded-full h-6 w-6 flex items-center justify-center">
                            <div
                              id={"radio"}
                              className={`${
                                targetedValue === btn.value
                                  ? "bg-secondMainColor"
                                  : "bg-white"
                              } w-4 h-4 cursor-pointer rounded-full `}
                              onClick={(e) => HandleRadioChange(e, index)}
                            ></div>{" "}
                          </div>
                          <input
                            id={btn.value}
                            type={btn.type}
                            name={btn.apikey}
                            value={btn.value}
                            className="w-4 h-4 hidden"
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div
              className={`  
      ${
        targetedValue
          ? "h-[70px]  relative top-4 overflow-hidden bg-transparent"
          : "h-0 relative top-4 bg-transparent"
      }  
      overflow-hidden  opacity-100    transition-all ease-in-out delay-100 duration-500  
     
      `}
            >
              <div className="w-fit ml-auto px-8 flex gap-4">
                <button
                  type="button"
                  className="w-fit flex justify-end bg-red-600 capitalize text-mainColor rounded-md px-6 py-1.5"
                  onClick={(e) => handleClear(e)}
                >
                  clear
                </button>

                {filteredData && filteredData.length > 0 && (
                  <button
                    type="submit"
                    className="w-fit flex justify-end bg-green-600 capitalize text-mainColor rounded-md px-6 py-1.5"
                    onClick={(e) => handleNext(e)}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="overflow-x-auto">
            <Table
              Head={Thead}
              counter={counter}
              setCounter={setCounter}
              data={filteredData}
              paginationEnd={pagination.end}
            >
              {Filter(filteredData)
                .slice(StartValue, EndValue)
                .map((val, i) => {
                  return (
                    <tr
                      key={i}
                      width={val.width}
                      className="border-b    hover:bg-white text-center"
                    >
                      <td className="px-3 py-7 ">{StartValue + 1 + i}</td>
                      <td className="px-3 font-semibold capitalize">
                        {val?.user?.fullName}
                      </td>
                      <td className="px-3 text-sm">{val?.coin}</td>
                      <td className="px-1 text-sm">$ {val?.tradeAmount}</td>
                      <td
                        className={`px-3 flex items-center justify-center  mt-5`}
                      >
                        {targetedValue === val?.timestamp ? (
                          <div className={`px-3  flex items-center `}>
                            <input
                              type="checkbox"
                              name="name"
                              defaultChecked={targetedValue ? true : false}
                              className="w-5 h-5"
                            />
                          </div>
                        ) : (
                          <div className={`px-3  flex items-center `}></div>
                        )}
                        <div
                          className={`${
                            targetedValue === val?.timestamp
                              ? "bg-green-600 px-3 py-1.5 text-white"
                              : ""
                          }`}
                        >
                          {val?.timestamp} min
                        </div>
                        {showModel && (
                          <MasterFormModel
                            getUserData={getTradeData}
                            setClickReject={setShowModel}
                            title="percentage"
                            data={filteredData}
                            setTargetedValue={setTargetedValue}
                            tradeIds={storeId}
                            targetedValue={targetedValue}
                            Id={Id}
                            message={showModel}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
            </Table>
          </div>
        </div>
      ) : (
        <div>
          <Table Head={Thead}></Table>
          <div className=" flex justify-center  lg:mr-20 items-center h-[500px] capitalize text-gray-600 font-semibold text-xl">
            {!showLoader && `no data available  yet`}
          </div>
        </div>
      )}
    </ContextApi>
  );
}

export default CoinBuyData;
