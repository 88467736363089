import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { BsPersonPlusFill } from "react-icons/bs";
import { AiFillEye, AiFillEyeInvisible, AiFillLock } from "react-icons/ai";
import Logo from "../../Resources/Images/LoginPage.png";
// import axios from "../../HOC/Axios/Axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cookies from "js-cookie";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "../../HOC/Axios/Axios";
import { FaTradeFederation } from "react-icons/fa";
// import Spinner from "../../components/pageComponents/Spinner/Spinner";
import Loader from "../../components/pageComponents/Spinner/loader";
function Login() {
  const router = useNavigate();
  const [clickEyeButton, setClickEyeButton] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const LoginForm = [
    {
      id: 0,
      type: "email",
      label: "Enter email address",
      placeholder: "Enter email address",
      apikey: "email",
      autocomplete: "on",
      icon: <BsPersonPlusFill />,
    },
    {
      id: 1,
      type: "password",
      label: "Password",
      placeholder: "Password",
      apikey: "password",
      icon: <AiFillLock />,
    },
  ];
  const LoginSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup
      .string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Password is required"),
  });

  const submit = (values) => {
    console.log(values);
    setShowSpinner(true);
    axios
      .post("/admin/login", values)
      .then((res) => {
        if (res.status === 201) {
          toast.success("Login sucessfully", { toastId: "AddedSuccess" });
          // (res.data.token);
          cookies.set("adminToken", res.data.token);
          setShowSpinner(false);

          setTimeout(() => {
            router("/");
          }, 1500);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(err.message);
        toast.error(err.message, { toastId: "Error" });
        setShowSpinner(false);
      });
  };

  const showText = () => {
    setClickEyeButton(!clickEyeButton);
  };
  const [focusedInput, setFocusedInput] = useState("");
  const handleInputFocus = (id) => {
    setFocusedInput(id);
  };
  const handleInputBlur = () => {
    setFocusedInput(null);
  };
  useEffect(() => {
    // Disable scrolling when the component mounts
    document.body.style.overflow = "hidden";

    // Re-enable scrolling when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div className="tablet:grid grid-cols-12  tablet:h-fit ">
      <div className="col-span-7 tablet:block hidden  w-full h-fit">
        {showSpinner && <Loader />}
        <ToastContainer autoClose={1000} pauseOnHover={false} />

        <div className=" cursor-pointer relativ  w-full  laptopM:py-0 ">
          <div className=" mx-auto left-[10%]  absolute top-[200px]">
            <div className="text-4xl font-bold tracking-wider text-white">
              Have something here atleast
            </div>
            <div className="text-[15px] text-justify w-8/12  text-white mt-5">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vero eum
              nemo optio vel illum, quod minus perferendis?
            </div>
          </div>
          <img
            alt="logo"
            src={Logo}
            width={100}
            height={10}
            className="  h-[820px] w-full object-cover overflow-hidden object-top"
          />
        </div>
      </div>
      <div className=" col-span-5 px-8 flex items-start justify-center h-screen overflow-hidden tablet:h-full flex-col">
        <div className="flex items-center gap-3">
          <div className="">
            <FaTradeFederation className="text-[60px] rounded-lg bg-[#833CDC] text-white p-2" />
          </div>
          <div className=" capitalize text-3xl tracking-wider  text-[#833CDC] font-extrabold">
            uni
            <br /> trading
          </div>
        </div>
        <div className="font-bold text-2xl text-[#444141] mt-10 mb-1.5  tracking-wide capitalize">
          admin login
        </div>
        <div className="text-xs text-light text-gray-600">
          Enter your credential to login into the dashboard
        </div>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={LoginSchema}
          onSubmit={(values) => {
            console.log(values);
            submit(values);
          }}
        >
          {({ errors, touched, values }) => (
            <Form className=" w-full mx-auto">
              <div className="  w-full  ">
                {" "}
                {LoginForm.map((val, i) => {
                  return (
                    <div key={i} className="my-8 w-full">
                      <div className="relative  ">
                        <div className="relative">
                          <label
                            onClick={(e) => handleInputFocus(val.id)}
                            htmlFor={val.apikey}
                            className={`absolute tracking-wider ${
                              focusedInput === val.id ||
                              values[val.apikey] !== ""
                                ? "top-1 text-[13px] font-normal"
                                : "top-5 text-[15px] font-medium "
                            } left-5 transition-all ease-in-out duration-300 text-gray-500 `}
                          >
                            {val.label}
                          </label>

                          {/* {val.label}
                          </div> */}
                          <Field
                            id={val.apikey}
                            onClick={() => handleInputFocus(val.id)}
                            onBlur={handleInputBlur}
                            name={val.apikey}
                            type={
                              !clickEyeButton && val.apikey === "password"
                                ? (val.type = "password")
                                : (val.type = "text")
                            }
                            autoComplete={
                              val.apikey === "password"
                                ? val.autocomplete
                                : null
                            }
                            // placeholder={val.placeholder}
                            className={` px-5 shadow-sm  text-sm h-[58px]  outline-none w-full  rounded-sm
                           ${
                             focusedInput === val.id ||
                             values[val.apikey] !== ""
                               ? " border-[1.5px] border-[#833CDC]"
                               : "border-[1.5px]  text-sm border-gray-500 "
                           }
                            `}
                          />
                          {val.apikey === "password" ? (
                            <div className="absolute top-5 right-5 z-50">
                              {clickEyeButton ? (
                                <button
                                  type="button"
                                  onClick={() => showText()}
                                >
                                  <AiFillEye className="text-xl" />
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() => showText()}
                                >
                                  <AiFillEyeInvisible className="text-xl" />
                                </button>
                              )}
                            </div>
                          ) : null}
                        </div>
                        {/* <div
                          className="text-white p-[9px] bg-[#833CDC] absolute top-0
                             left-0 rounds text-2xl"
                        >
                          {val.icon}
                        </div> */}
                      </div>
                      {errors[val.apikey] && touched[val.apikey] && (
                        <div className="mt-2 text-red-400 text-sm px-2 py-1 rounded-sm">
                          {errors[val.apikey]}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <div>
                {" "}
                <NavLink to={"/forgotPassword"}>
                  <div
                    className="text-center tracking-wide cursor-pointer  hover:scale-105
                   transition-all ease-in-out duration-300 hover:underline capitalize font-medium
                   text-primary mt-2 mb-4"
                  >
                    forgot password
                  </div>
                </NavLink>
              </div>
              <div className="">
                <button
                  className="bg-[#833cdc] hover:bg-opacity-90 w-full   transition-all
                       ease-in-out duration-300 px-4 py-3 rounded-tablet text-white font-semibold"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Login;
