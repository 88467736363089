import cookies from "js-cookie";
import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Context = createContext();
function ContextApi({ children }) {
  const navigate = useNavigate();
  const [cookievalue, setCookieValue] = useState(null);

  useEffect(() => {
    let adminTokens = cookies.get("adminToken");
    if (adminTokens) {
      setCookieValue(adminTokens);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div>
      <Context.Provider value={{ cookievalue, setCookieValue }}>
        {children}
      </Context.Provider>
    </div>
  );
}

export default ContextApi;
