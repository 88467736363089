import React from "react";
import { MdClose } from "react-icons/md";
import ScrollOff from "../../components/pageComponents/WindowScrollOff/ScrollOff";
function ImageModel({ image, setModelClick, message }) {
  const handleClick = () => {
    setTimeout(() => {
      setModelClick(false);
    }, 0);
  };

  return (
    <ScrollOff message={message}>
      <div
        onClick={() => handleClick()}
        className={`fixed bg-black  top-0 flex    justify-center
left-0  z-50  overflow-y-scroll items-center  h-screen w-full bg-opacity-50`}
      >
        <div className="flex h-6/6  w-full   justify-center ">
          <div
            className="  w-11/12 laptop:w-6/12  tablet:h-[700px]  "
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <img
              alt="Loading..."
              src={image}
              width={100}
              height={100}
              className="w-full h-full object-contain bg-white  object-top"
            />
          </div>
          <div
            className="bg-gray-600  z-50 ml-5  absolute  right-4 tablet:right-0 tablet:relative  w-fit  top-0 tablet:top-0 h-fit text-white mt-8 p-1 tablet:p-2.5 cursor-pointer rounded-full"
            onClick={() => handleClick()}
          >
            <MdClose className=" text-xl tablet:text-3xl" />
          </div>
        </div>
      </div>
    </ScrollOff>
  );
}

export default ImageModel;
