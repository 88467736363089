import React, { useState } from "react";
// import AddAmountData from "../../components/pageComponents/UserDetailFormModel/UserAmount/AddAmountData";
// import UserUpdateAmount from "../../components/pageComponents/UserDetailFormModel/UserUpdateAmount";
import AddAmount from "../../components/pageComponents/UserDetailFormModel/UserAmount/AddAmount";
import { IoClose } from "react-icons/io5";
import SubtractAmount from "../../components/pageComponents/UserDetailFormModel/UserAmount/SubtractAmount";
import ScrollOff from "../../components/pageComponents/WindowScrollOff/ScrollOff";
// import Backdrop from "../Backdrop/Backdrop";

// import Model from "../model/Model";
function MasterDeleteModal({
  message,
  setMessage,
  Delete,
  content,
  storeAcceptID,
  Submited,
  SubmitWithAmountDisplay,
  getUserData,
  setStoreAcceptID,
  type,
}) {
  const [addMoney, setAddMoney] = useState(false);

  const handleNext = () => {
    setAddMoney(true);
  };

  return (
    <div>
      {addMoney && SubmitWithAmountDisplay && type === "Deposit" && (
        <div className="px-4 fixed    left-[25%]  top-[35%] z-50 h-full w-full    ">
          <div className="h-fit bg-[white] w-6/12  py-6 ">
            <div className=" flex items-center mb-5 px-4   ">
              <div className=" text-[16px] font-semibold text-left ">
                credit amount to user
              </div>
              <div
                className="text-lg cursor-pointer  p-1 border
         text-white bg-red-600 rounded-full w-fit ml-auto "
                onClick={() => setAddMoney(false)}
              >
                <IoClose />
              </div>
            </div>
            <AddAmount
              addMoney={addMoney}
              getUserData={getUserData}
              SubmitWithAmountDisplay={SubmitWithAmountDisplay}
              setMessage={setMessage}
              storeAcceptID={storeAcceptID}
              setStoreAcceptID={setStoreAcceptID}
              type={type}
              setAddMoney={setAddMoney}
            />
          </div>
        </div>
      )}
      {addMoney && SubmitWithAmountDisplay && type === "withdrawal" && (
        <div className="px-4 fixed    left-[25%]  top-[35%] z-50 h-full w-full    ">
          <div className="h-fit bg-[white] w-6/12  py-6 ">
            <div className=" flex items-center mb-5 px-4">
              <div className=" text-[16px] font-semibold text-left ">
                debit amount from user
              </div>
              <div
                className="text-lg cursor-pointer  p-1 border
         text-white bg-red-600 rounded-full w-fit ml-auto "
                onClick={() => setAddMoney(false)}
              >
                <IoClose />
              </div>
            </div>
            <SubtractAmount
              getUserData={getUserData}
              SubmitWithAmountDisplay={SubmitWithAmountDisplay}
              setMessage={setMessage}
              storeAcceptID={storeAcceptID}
              setStoreAcceptID={setStoreAcceptID}
              type={type}
              setAddMoney={setAddMoney}
            />
          </div>
        </div>
      )}
      <ScrollOff message={message}>
        <div
          className={`fixed bg-black  z-20 left-0 top-0  flex   flex-col  justify-center
     h-full w-full bg-opacity-50`}
        >
          {/* <div className=" "> */}
          <div className="   flex items-center   justify-center  mx-auto tablet:w-7/12 h-screen">
            <div
              className="bg-mainColor  shadow-sm shadow-gray-500
           rounded-lg   px-5    font-openSansFive text-lg flex  flex-col   justify-center"
            >
              <div className="py-8 text-gray-700">
                {content ? content : "Are you sure you wanna delete this?"}
              </div>
              <div className=" flex flex-row-reverse items-start   text-base  w-11/12 pb-8 gap-3">
                {content ? (
                  <div
                    className="bg-green-700 text-white shadow-sm shadow-gray-400 hover:scale-105
               hover:bg-opacity-80 flex px-5 py-1 cursor-pointer  capitalize rounded-lg justify-center items-center"
                    onClick={() =>
                      SubmitWithAmountDisplay ? handleNext() : Submited()
                    }
                  >
                    <button>
                      {SubmitWithAmountDisplay ? "Next" : "Accept"}
                    </button>
                  </div>
                ) : (
                  <div
                    className="bg-red-500 text-white shadow-sm shadow-red-400 hover:scale-105
               hover:bg-red-700 flex px-3 py-1 cursor-pointer  capitalize rounded-lg justify-center items-center"
                    onClick={() => Delete()}
                  >
                    <button>Delete</button>
                  </div>
                )}
                <div
                  className="bg-gray-300  hover:text-white text-gray-700 px-3 py-1 
              flex capitalize shadow-sm hover:scale-105
               hover:bg-gray-400 shadow-gray-400 rounded-lg justify-center items-center"
                >
                  <button onClick={() => setMessage(false)}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </ScrollOff>
    </div>
  );
}

export default MasterDeleteModal;
