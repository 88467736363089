import React, { useState, useEffect } from "react";
// import { CircularProgressbar } from "react-circular-progressbar";
// import "react-circular-progressbar/dist/styles.css";
import axios from "../../HOC/Axios/Axios";
// import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// import storage from "../../../../HOC/FIREBASE/FIREBASE";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Table from "../../UI/Table";

import MasterDeleteModal from "../../UI/MasterDeleteModal/MasterDeleteModal";
import TableFilter from "../../components/pageComponents/TableFilter/TableFilter";
import ContextApi from "../../HOC/ContextApi/ContextApi";
import MasterFormModel from "../../UI/RejectionFormModel/RejectionFormModel";
import Loader from "../../components/pageComponents/Spinner/loader";
import Spinner from "../../components/pageComponents/Spinner/Spinner";
function WithdrawByQRCode() {
  const [message, setMessage] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [storeId, setstoreId] = useState("");
  const [storeAcceptID, setStoreAcceptID] = useState("");
  // const [storeUserId, setStoreUserId] = useState("");
  const [status, setStatus] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  // form names and keys for mapping
  // const [progress, setprogress] = useState(0);
  // const [progressShow] = useState(false);
  const [filteredData, setFilteredData] = useState("");
  const [counter, setCounter] = useState(1);
  const [pagination] = useState({
    start: 0,
    end: 10,
  });
  // pagination logic
  const EndValue = counter * pagination.end;
  const StartValue = EndValue - pagination.end;
  // console.log(StartValue, EndValue);
  const [clickReject, setClickReject] = useState(false);

  // let Spinner;
  // if (progressShow) {
  //   Spinner = (
  //     <div
  //       style={{ background: "rgba(0,0,0,.5)" }}
  //       className="fixed h-screen w-screen top-0 left-0 z-50 flex items-center justify-center"
  //     >
  //       <div className="h-44 w-44  rounded-md z-50">
  //         {/* <CircularProgressbar value={progress} text={`${progress}%`} /> */}
  //       </div>
  //     </div>
  //   );
  // }
  // console.log(Spinner);

  const Thead = [
    { title: "s.nO", with: "10%" },
    { title: "full name", with: "10%" },
    { title: "date", with: "20%" },
    { title: "wallet address", with: "10%" },
    { title: "wallet amount", with: "20%" },
    { title: "status", with: "10%" },
    { title: "action", with: "20%" },
  ];
  // const Tbody = [
  //   {
  //     name: "kabir",
  //     bank: "nabil",
  //     account_number: "1234512012323284",
  //     amount: "2000",
  //     status: "pending",
  //   },
  //   {
  //     name: "kashish",
  //     bank: "nabil",
  //     account_number: "1234512012323284",
  //     amount: "2000",
  //     status: "pending",
  //   },
  //   {
  //     name: "ashish",
  //     bank: "nabil",
  //     account_number: "1234512012323284",
  //     amount: "2000",
  //     status: "pending",
  //   },
  //   {
  //     name: "deepak",
  //     bank: "nabil",
  //     account_number: "1234512012323284",
  //     amount: "2000",
  //     status: "pending",
  //   },
  //   {
  //     name: "mahi",
  //     bank: "nabil",
  //     account_number: "1234512012323284",
  //     amount: "2000",
  //     status: "pending",
  //   },
  //   {
  //     name: "ambani",
  //     bank: "nabil",
  //     account_number: "1234512012323284",
  //     amount: "2000",
  //     status: "pending",
  //   },
  //   {
  //     name: "zukerberg",
  //     bank: "nabil",
  //     account_number: "1234512012323284",
  //     amount: "2000",
  //     status: "pending",
  //   },
  // ];

  const HandleSearchInputChange = (e) => {
    // console.log(e.target.value);
    setFilteredData(e.target.value);
  };

  const Filter = (options) => {
    return options.filter((option, index) => {
      return (
        option?.user?.fullName
          .toLowerCase()
          .indexOf(filteredData.toLowerCase()) > -1
      );
    });
  };

  const getUserData = () => {
    setShowLoader(true);
    axios
      .get(`/withdrawal/GetAllUSDTdata`)
      .then((res) => {
        setTableData(res?.data?.data);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        console.log(err);
      });
  };
  useEffect(() => {
    getUserData();
  }, []);
  // console.log(storeAcceptID);
  const Submit = () => {
    const status = "completed";
    setShowSpinner(true);

    // console.log(storeAcceptID, status);
    axios
      .patch(`/withdrawal/updateUSDTStatus/${storeAcceptID.id}`, {
        status: status,
        userId: storeAcceptID.id,
      })
      .then((res) => {
        if (res.status === 200) {
          toast.info("Updated status sucessfully");
          setTimeout(() => {
            setMessage(false);
            setShowSpinner(false);
            getUserData();
          }, 900);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        setShowSpinner(false);
      });
  };

  const Conformation = (val) => {
    console.log(val);
    setMessage(true);
    // setStoreUserId(userId);
    setStoreAcceptID(val);
    // setIdStore(id);
    // console.log(id);
  };

  let ConformationMessage;
  if (message) {
    ConformationMessage = (
      <MasterDeleteModal
        content={"confirmation message to accept?"}
        SubmitWithAmountDisplay={Submit}
        storeAcceptID={storeAcceptID}
        message={message}
        setMessage={setMessage}
        getUserData={getUserData}
        type="withdrawal"
      />
    );
  }

  const handleReject = (id) => {
    const status = "rejected";
    setStatus(status);
    setstoreId(id);
    setClickReject(true);
  };

  const pendingData = tableData.filter((val) => val.status === "pending");
  return (
    <ContextApi>
      {showLoader && <Loader />}
      {ConformationMessage}
      {showSpinner && <Spinner />}
      <ToastContainer autoClose={1000} />
      {clickReject ? (
        <MasterFormModel
          setClickReject={setClickReject}
          clickReject={clickReject}
          message={clickReject}
          Id={storeId}
          status={status}
          getUserData={getUserData}
          title="withdraw rejection by usdt"
          userId={tableData[0]?.user?.id}
        />
      ) : (
        ""
      )}

      <TableFilter
        HandleSearchInputChange={HandleSearchInputChange}
        placeholder={"search by name..."}
      />
      {tableData.length > 0 && pendingData.length > 0 ? (
        <div className="overflow-x-auto">
          {Filter(pendingData).length > 0 ? (
            <Table
              Head={Thead}
              counter={counter}
              setCounter={setCounter}
              data={pendingData}
              paginationEnd={pagination.end}
            >
              {Filter(pendingData)
                .slice(StartValue, EndValue)
                .map((val, i) => {
                  return (
                    <tr
                      key={i}
                      width={val.width}
                      className="border-b text-sm capitalize hover:bg-white text-center"
                    >
                      <td className="px-3 py-7 ">{StartValue + 1 + i}</td>
                      <td className="px-3 font-semibold text-[15px] capitalize">
                        {val?.user?.fullName}
                      </td>
                      <td className="px-3 font-semibold text-[15px] capitalize">
                        {new Date(val?.createdAt).toDateString()}
                      </td>

                      <td className="px-3">{val?.walletAddress}</td>
                      <td className="px-1">{val?.withdrawalAmount}</td>

                      <td className="px-3text-sm font-light  text-yellow-500 capitalize">
                        {val?.status}
                      </td>

                      <td className="px-3">
                        <div
                          className=" p-2.5 capitalize text-sm flex gap-3 items-center  justify-center
                     border-gray-300 "
                        >
                          <div
                            className="px-3 py-1.5 border w-20 hover:bg-green-600 bg-white hover:text-white  rounded-[3px]  cursor-pointer"
                            onClick={() => Conformation(val)}
                          >
                            accept
                          </div>
                          <div
                            className="px-3 py-1.5 border w-20 hover:bg-red-500 bg-white hover:text-white  rounded-[3px]  cursor-pointer"
                            onClick={() => handleReject(val.id)}
                          >
                            reject
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </Table>
          ) : (
            <div className="text-left px-8 py-3">
              <p className="text-gray-500 font-medium">No data found......</p>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Table Head={Thead}></Table>
          <div className=" flex justify-center items-center h-[400px] capitalize text-gray-600 font-semibold text-xl">
            {!showLoader && `no data available yet`}
          </div>
        </div>
      )}
    </ContextApi>
  );
}

export default WithdrawByQRCode;
