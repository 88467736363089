import React from "react";
// import axios from "../HOC/Axios/Axios";
import { NavLink } from "react-router-dom";
// import Spinner from "../components/UI/Spinner/Spinner";
// import PasswordToolbar from "../components/PageComponents/HomeSection/subcomponents/PasswordToolbar";
// import Footer from "../HOC/Footer/Footer";
import image from "../../Resources/Images/profile.jpeg";
import Logo from "../../Resources/Images/bitBgRemove.png";
import { AiFillInstagram } from "react-icons/ai";
import { BsTwitter, BsWhatsapp } from "react-icons/bs";
import { FaFacebookF, FaTiktok } from "react-icons/fa";
function ResetPassword() {
  // const [datas, setDatas] = useState([]);
  // const [spinner, setSpinner] = useState(false);
  const data = [{}];
  // const router = useNavigate();
  const submit = () => {
    console.log("submitted");
  };
  //   const submit = () => {
  //     try {
  //       setSpinner(true);
  //       localStorage.setItem("userId", router.query.id);
  //       axios
  //         .post(`/student/forgetpassword/${router.query.id}`)

  //         .then((res) => {
  //           if (res.status === 201) {
  //             setSpinner(false);
  //             router.push({
  //               pathname: "/auth/login",
  //               query: {
  //                 first_name: `${res.data.data.first_name}`,
  //                 last_name: `${res.data.data.last_name}`,
  //                 successful: "updated",
  //               },
  //             });
  //           }
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  return (
    <div className="bg-secondMainColor h-screen">
      {/* <PasswordToolbar /> */}
      <div className=" p-2  bg-white  block ">
        <div className="w-24 cursor-pointer">
          <NavLink href={"/login"} passHref>
            <img
              alt="logo"
              src={Logo}
              width={70}
              height={70}
              sizes={"80vw"}
              quality={75}
              className="object-contain"
            />
          </NavLink>
        </div>
      </div>

      <div className="bg-gray-100 w-full   flex items-center text-center md:text-left  ">
        <div className="bg-white my-10   w-11/12 md:w-fit tablet:w-5/12 mx-auto rounded-lg ">
          <div className="capitalize p-5 text-gray-800 text-xl font-openSansSeven">
            reset your password
          </div>
          <div className="border-b w-full text-gray-100"></div>
          {/* middle section  starts */}

          <div className=" px-5 pt-4 text-sm sm:text-base  font-MonteSerratFive md:w-8/12 text-gray-700">
            How do you want to get the code to reset your password?{" "}
          </div>
          {/* middle section left part */}
          <div className="flex flex-col md:flex-row md:justify-between mt-4 md:mt-0 items-center ">
            <div className="px-4">
              {data.map((val, i) => {
                return (
                  <div key={i} className="m-2 flex gap-2">
                    <div className="">
                      <input
                        id="radio"
                        type="radio"
                        className="w-4 h-4 cursor-pointer"
                      />
                    </div>
                    <div>
                      <div className=" text-sm md:text-base font-MonteSerratFour">
                        <label htmlFor="radio">SendCodeVia Email</label>
                      </div>
                      <div className="text-xs md:text-sm  font-MonteSerratThree">
                        {/* {router.query.email} */} johndoe@gmail.com
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* middle section right part */}

            <div className="px-4 flex flex-col mt-4 items-center ">
              <div className="w-14 md:w-16 ">
                <img
                  src={image}
                  alt="logo"
                  width={"45%"}
                  height={"45%"}
                  sizes={"60vw"}
                  className="rounded-full w-14 h-14"
                />
              </div>
              <div className=" text-sm md:text-base capitalize my-2 md:mt-2 md:my-0 font-MonteSerratFive ">
                {/* {router.query.first_name} {router.query.last_name} */}
                john doe
              </div>
            </div>
          </div>

          <div className="border-b py-3 w-full text-gray-100"></div>
          {/* bottom section for nolonger and continue buttons */}
          <div className="flex flex-col md:flex-row items-center lg:mt-0 mt-4 justify-between cursor-pointer px-4">
            <div className="hover:underline text-xs text-secondMainColor w-fit    md:text-sm font-MonteSerratFour ">
              No longer have access to these?
            </div>

            <div className=" flex flex-row-reverse  gap-4 py-4 ">
              <div
                type="button"
                onClick={() => submit()}
                className="bg-secondMainColor text-sm md:text-base text-white flex items-center rounded-lg hover:scale-105 transition-all duration-300 delay-75 ease-in-out cursor-pointer font-openSansSix  h-9 px-2 md:px-3 lg:px-4"
              >
                Continue
              </div>

              <NavLink to={"/forgotPassword"}>
                <div
                  type="button"
                  className="bg-gray-200 text-sm md:text-base font-openSansSix flex rounded-lg items-center hover:scale-105 transition-all duration-300 delay-75 ease-in-out cursor-pointer h-9 px-2 md:px-3 lg:px-4"
                >
                  Not you?
                </div>
              </NavLink>
            </div>
          </div>
        </div>
        {/* {spinner ? <Spinner /> : null} */}
      </div>
      <div className="  tablet:mt-20">
        <div className=" w-10/12  mx-auto">
          {" "}
          <hr className="opacity-40   laptop:my-8" />
        </div>
        <div className="lg:container lg:mx-auto px-3 laptop:px-4 lg:px-8 py-8 laptop:py-0">
          <div className="flex flex-col tablet:flex-row items-start justify-between py-5 space-y-5 laptop:space-y-0">
            <NavLink to={"/"} passHref>
              <div className="w-28 -mt-2 cursor-pointer">
                <img
                  src={Logo}
                  alt="logo"
                  height={50}
                  sizes={"80vw"}
                  width={100}
                  placeholder="blur"
                  priority={"true"}
                  loading="eager"
                  blurDataURL="imageLogo"
                  quality={75}
                  className="object-contain"
                />
              </div>
            </NavLink>

            {/* privacy policy section starts  */}
            <div className=" flex-1 laptop:mx-8 lg:mx-16">
              <div className="flex items-center space-x-1 laptop:space-x-2 text-white font-medium  text-xs laptop:text-sm">
                <NavLink to="">
                  <p className="cursor-pointer transition hover:opacity-80">
                    Terms & Condition
                  </p>
                </NavLink>
                <span className="h-4 w-0.5 bg-white"></span>
                <NavLink href="/Policy_Aggrement/privacy_policy">
                  <p className="cursor-pointer transition hover:opacity-80">
                    Privacy Policy
                  </p>
                </NavLink>
                <span className="h-4 w-0.5 bg-white"></span>
                <NavLink href="">
                  <p className="cursor-pointer transition hover:opacity-80">
                    Lisence Aggrement
                  </p>
                </NavLink>
              </div>
              <p className="mt-5  laptop:my-4 text-xs text-white">
                Copyright &copy; {new Date().getFullYear()}, DubaiCrypto
              </p>
            </div>

            {/* privacy policy section ends  */}
            {/* social media icons section  */}
            <div className="flex items-center space-x-4 text-xl">
              <FaFacebookF className="text-white cursor-pointer transition hovertext" />
              <BsTwitter className="text-white cursor-pointer transition hovertext" />
              <AiFillInstagram className="text-white cursor-pointer transition hovertext" />
              <FaTiktok className="text-white cursor-pointer transition hovertext" />
              <BsWhatsapp className="text-white cursor-pointer transition hovertext" />
            </div>
            {/* social media icon section ends  */}
          </div>

          {/* bottom section ends  */}
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
