import React, { useState } from "react";
import axios from "../../../../HOC/Axios/Axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../Spinner/Spinner";
function SubtractAmount({
  setShowUpdateAmount,
  getUserData,
  setAddMoney,
  setMessage,
  SubmitWithAmountDisplay,
  storeAcceptID,
  type,
}) {
  const [showSpinner, setShowSpinner] = useState(false);
  const Sumbit = (e) => {
    e.preventDefault();
    setShowSpinner(true);
    console.log(storeAcceptID?.user?.id, type);

    // const subValue = sub.current.value;
    axios
      .patch("/Amount/sub", {
        balance: changedValue,
        userId:
          type === "withdrawal" ? storeAcceptID?.user?.id : storeAcceptID?.id,
      })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success("Amount has been deducted sucessfully");
          setTimeout(() => {
            setShowSpinner(false);
            getUserData();
            if (type === "withdrawal") {
              SubmitWithAmountDisplay();
              setAddMoney(false);
              setMessage(false);
            } else {
              setShowUpdateAmount(false);
            }
          }, 1000);
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data.message);
        setShowSpinner(false);
      });
  };
  const [changedValue, setChangedValue] = useState(
    storeAcceptID?.withdrawalAmount || ""
  );
  const handleChangeValue = (e) => {
    setChangedValue(e.target.value);
  };
  return (
    <div className="px-4">
      {showSpinner && <Spinner />}

      <ToastContainer />
      <form
        className="flex  relative flex-col capitalize "
        onSubmit={(e) => Sumbit(e)}
      >
        <div className="absolute  -top-3 left-3  px-2 ">
          <label className="font-medium  capitalize text-[13px]  mx-auto  px-3  text-[#9b9b9b] bg-[white]  capitalize">
            debit amount
          </label>
        </div>
        <div className="border border-[#c8c8c8]">
          <input
            type="number"
            name="amount"
            min="0"
            // ref={sub}
            onChange={(e) => handleChangeValue(e)}
            defaultValue={
              // type === "Deposit"
              //   ? storeAcceptID?.amount
              storeAcceptID?.withdrawalAmount
            }
            placeholder="Amount"
            className="border-none px-2 outline-none  w-full  h-14 "
          />
        </div>
        <div className="flex flex-col mt-8 items-end  relative">
          <button
            className={`px-[47px] py-[10px] bg-[#833CDC] flex items-center gap-1 rounded-md text-white `}
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default SubtractAmount;
