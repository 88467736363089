import React, { useEffect } from "react";

function ScrollOff({ message, children }) {
  // useEffect(() => {
  useEffect(() => {
    if (message === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    // Cleanup function to reset overflow when component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [message]);
  return <div>{children}</div>;
}

export default ScrollOff;
