import React, { useState, useEffect } from "react";
// import Spinner from "../components/UI/Spinner/Spinner";
// import PasswordToolbar from "../components/PageComponents/HomeSection/subcomponents/PasswordToolbar";
// import Footer from "../../HOC/Footer/Footer";
import Logo from "../../Resources/Images/LoginPage.png";
import { Formik, Form, Field } from "formik";
// import * as Yup from "yup";
import { NavLink, useNavigate } from "react-router-dom";
// import { AiFillInstagram } from "react-icons/ai";
// import { BsTwitter, BsWhatsapp } from "react-icons/bs";
// import { FaFacebookF, FaTiktok } from "react-icons/fa";
import { forgotPasswordSchema } from "../../UI/RegxSchema/RegXSchema";
import Loader from "../../components/pageComponents/Spinner/loader";
import axios from "../../HOC/Axios/Axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaTradeFederation } from "react-icons/fa";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
function ForgotPassword() {
  const [showSpinner, setShowSpinner] = useState(false);
  const [showPassword, setShowPassword] = useState({});

  const navigate = useNavigate();
  const updatePassword = [
    {
      label: "email",
      type: "text",
      apikey: "email",
      placeholder: "Enter your email address",
    },

    {
      label: "old password",
      type: "password",
      apikey: "oldPassword",
      placeholder: "Enter old Password",
      icon1: <AiFillEye />,
      icon2: <AiFillEyeInvisible />,
    },
    {
      label: "new password",
      type: "password",
      apikey: "newPassword",
      placeholder: "Enter new Password",
      icon1: <AiFillEye />,
      icon2: <AiFillEyeInvisible />,
    },
    {
      label: "confirm new password",
      type: "password",
      apikey: "confirmNewPassword",
      placeholder: "Confirm your new Password",
      icon1: <AiFillEye />,
      icon2: <AiFillEyeInvisible />,
    },
  ];

  const handleSubmit = async (values, resetForm) => {
    console.log(values);
    setShowSpinner(true);
    try {
      let response = await axios.patch("/admin/changepassword", values);
      console.log(response);
      toast.success("Password updated sucessfully", { toastId: "Error" });
      setInterval(() => {
        if (response.status === 200) {
          setShowSpinner(false);
          navigate("/login");
          resetForm();
        }
      }, 300);
    } catch (error) {
      setShowSpinner(false);
      toast.error(error.message, { toastId: "Error" });
    }
  };
  const [ID, setID] = useState({});
  const handleClicked = (apikey) => {
    setID((prev) => ({ ...prev, [apikey]: true }));
  };
  const handleInputBlur = (e, apikey) => {
    if (e.target.value.length > 0) {
      setID((prev) => ({ ...prev, [apikey]: true }));
    } else {
      setID((prev) => ({ ...prev, [apikey]: false }));
    }
  };
  const handleHidePassword = (apikey) => {
    setShowPassword({ ...showPassword, [apikey]: false });
  };
  const handleShowPassword = (apikey) => {
    setShowPassword({ ...showPassword, [apikey]: true });
  };
  useEffect(() => {
    // Disable scrolling when the component mounts
    document.body.style.overflow = "hidden";

    // Re-enable scrolling when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div className=" tablet:grid grid-cols-12 tablet:h-fit bg-gray-100 ">
      <div className="  hidden tablet:block col-span-7 cursor-pointer relativ  w-full  laptopM:py-0 ">
        <ToastContainer autoClose={1000} pauseOnHover={false} />
        {showSpinner ? <Loader /> : null}
        <div className=" mx-auto left-[10%]  absolute top-[200px]">
          <div className="text-4xl font-bold tracking-wider text-white">
            Have something here atleast
          </div>
          <div className="text-[15px] text-justify w-8/12  text-white mt-5">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vero eum
            nemo optio vel illum, quod minus perferendis?
          </div>
        </div>
        <img
          alt="logo"
          src={Logo}
          width={100}
          height={10}
          className="  h-[820px] w-full object-cover overflow-hidden object-top"
        />
      </div>
      <div className=" col-span-5 px-8 flex items-start justify-center desktop:h-full h-screen overflow-hidden tablet:h-full flex-col">
        <div className="    w-11/12  mx-auto rounded-lg ">
          <div className="flex items-center gap-3">
            <div className="">
              <FaTradeFederation className="text-[60px] rounded-lg bg-[#833CDC] text-white p-2" />
            </div>
            <div className=" capitalize text-3xl tracking-wider  text-[#833CDC] font-extrabold">
              update
              <br /> your password
            </div>
          </div>

          <Formik
            initialValues={{
              email: "",
              oldPassword: "",
              newPassword: "",
              confirmNewPassword: "",
            }}
            validationSchema={forgotPasswordSchema}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values, resetForm);
            }}
          >
            {({ errors, touched, values }) => (
              <Form>
                <div className="px-5 py-4 text-sm  font-MonteSerratFive text-gray-700">
                  You can change your password immediately because you are
                  logged into your email account on this browser.
                </div>
                <div className="px-5 ">
                  {updatePassword.map((val, i) => {
                    return (
                      <div className=" my-4 relative " key={i}>
                        <label
                          onClick={() => handleClicked(val.apikey)}
                          htmlFor={val.apikey}
                          className={` absolute tracking-wider 
                            ${
                              ID[val.apikey] || values[val.apikey]
                                ? "top-2 text-[12px] font-normal"
                                : "top-6 text-[14px] font-medium"
                            } font-normal
                           left-5 transition-all ease-in-out duration-300 text-gray-500 `}
                        >
                          {val.placeholder}
                        </label>
                        <div className="">
                          <Field
                            onFocus={() => handleClicked(val.apikey)}
                            onBlur={(e) => handleInputBlur(e, val.apikey)}
                            id={val.apikey}
                            type={
                              val.type === "password" &&
                              !showPassword[val.apikey]
                                ? "password"
                                : "text"
                            }
                            name={val.apikey}
                            // placeholder={val.placeholder}
                            className=" bg-gray-200   px-5 my-2 h-14 outline-none border border-gray-400 rounded-sm w-full"
                          />
                          <div className="absolute top-7 right-5 cursor-pointer">
                            {showPassword[val.apikey] ? (
                              <div
                                onClick={() => handleHidePassword(val.apikey)}
                              >
                                {val.icon1}
                              </div>
                            ) : (
                              <div
                                onClick={() => handleShowPassword(val.apikey)}
                              >
                                {val.icon2}
                              </div>
                            )}
                          </div>
                        </div>
                        {errors[val.apikey] && touched[val.apikey] ? (
                          <div className=" text-red-400 text-sm px-2  rounded-sm">
                            {errors[val.apikey]}
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
                <div className="  gap-4 py-2 px-4">
                  <button
                    type="submit"
                    className="bg-primary  text-sm font-semibold    w-full outline-none text-white bg-[#833CDC] flex items-center justify-center
                     rounded-sm hover:bg-opacity-80 transition-all duration-300 delay-75 ease-in-out 
                     cursor-pointer h-12 px-4"
                  >
                    Submit
                  </button>
                  <NavLink to={"/login"}>
                    <div
                      type="button"
                      className="flex  w-fit text-normal mx-auto font-semibold items-center justify-center  mt-4  rounded-sm 
                       hover:border-b-[1.5px] border-b-[1.5px] border-gray-100 hover:border-secondMainColor  text-secondMainColor transition-all duration-200  ease-in-out cursor-pointer   px-2 "
                    >
                      Back to login
                    </div>
                  </NavLink>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
