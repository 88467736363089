import React, { useState, useEffect } from "react";
// import { Field, Form, Formik } from "formik";
// import { CircularProgressbar } from "react-circular-progressbar";
// import "react-circular-progressbar/dist/styles.css";
// import axios from "../../../../HOC/CreateAxios/CreateAxios";
// import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// import storage from "../../../../HOC/FIREBASE/FIREBASE";
// import * as yup from "yup";
// import image from "../../Resources/Images/bitBgRemove.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from "../../UI/Table";
// import { MdDelete } from "react-icons/md";
import MasterDeleteModal from "../../UI/MasterDeleteModal/MasterDeleteModal";
import TableFilter from "../../components/pageComponents/TableFilter/TableFilter";
import ImageModel from "../../UI/ImageModel/ImageModel";
import MasterFormModel from "../../UI/RejectionFormModel/RejectionFormModel";
import ContextApi from "../../HOC/ContextApi/ContextApi";
import axios from "../../HOC/Axios/Axios";
// import Spinner from "../../components/pageComponents/Spinner/Spinner";
import Loader from "../../components/pageComponents/Spinner/loader";
function DepositByBank() {
  const [message, setMessage] = useState(false);
  const [modelClick, setModelClick] = useState(false);
  const [storeId, setstoreId] = useState("");
  const [tableData, setTableData] = useState([]);
  const [storeAcceptID, setStoreAcceptID] = useState("");
  const [status, setStatus] = useState("");
  const [filteredData, setFilteredData] = useState("");
  const [counter, setCounter] = useState(1);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [ID, setID] = useState("");
  const [pagination] = useState({
    start: 0,
    end: 10,
  });
  console.log(showSpinner);
  const EndValue = counter * pagination.end;
  const StartValue = EndValue - pagination.end;
  const [clickReject, setClickReject] = useState(false);

  const Thead = [
    { title: "s.nO", width: "5%" },
    { title: "name", width: "10%" },
    { title: "bank name", width: "10%" },
    { title: "account Number", width: "10%" },
    { title: "account Holder Name", width: "30%" },
    { title: "amount", width: "15%" },
    { title: "date", width: "10%" },
    { title: "voucherImage", width: "5%" },
    { title: "status", width: "5%" },
    { title: "action", width: "10%" },
  ];

  const HandleSearchInputChange = (e) => {
    setFilteredData(e.target.value);
  };

  const Filter = (options) => {
    return options.filter((option, index) => {
      return (
        option["accountHolderName"]
          .toLowerCase()
          .indexOf(filteredData.toLowerCase()) > -1 ||
        option?.user?.fullName
          .toLowerCase()
          .indexOf(filteredData.toLowerCase()) > -1
      );
    });
  };
  const getUserData = () => {
    // if (tableData.length > 0) {
    setShowLoader(true);
    // }
    axios
      .get(`/deposit`)
      .then((res) => {
        console.log(res?.data);
        setTableData(res?.data);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        // console.log(err.response.data.message);
      });
  };
  console.log(tableData);

  useEffect(() => {
    getUserData();
  }, []);

  const Submit = () => {
    // console.log(storeAcceptID.id, "depositId");
    // console.log(storeAcceptID.user.id, "userID");
    // setShowSpinner(true);
    const status = "completed";

    axios
      .patch(`/deposit/changeBankDepositStatus/${storeAcceptID.id}`, {
        status: status,
      })
      .then((res) => {
        if (res.status === 200) {
          // console.log(res)
          toast.success("Data has been added  sucessfully", {
            toastId: "AddedSuccess",
          });
          setTimeout(() => {
            setShowSpinner(false);
            setMessage(false);
            getUserData();
          }, 500);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message[0], { toastId: "Error" });

        setShowSpinner(false);
        setMessage(false);
        console.log(err);
      });
  };
  const Conformation = (val) => {
    setMessage(true);
    setStoreAcceptID(val);
  };

  let ConformationMessage;
  if (message) {
    ConformationMessage = (
      <MasterDeleteModal
        content={"confirmation message to accept?"}
        SubmitWithAmountDisplay={Submit}
        storeAcceptID={storeAcceptID}
        setStoreAcceptID={setStoreAcceptID}
        message={message}
        setMessage={setMessage}
        getUserData={getUserData}
        type="Deposit"
        // message={}
      />
    );
  }

  const handleReject = (id) => {
    const status = "rejected";
    setStatus(status);
    setstoreId(id);
    setClickReject(true);
  };

  const pendingData = tableData?.filter((val, i) => {
    return val.status === "pending";
  });
  const handleImage = (val) => {
    setModelClick(true);
    setID(val);
  };
  return (
    <ContextApi>
      {ConformationMessage}
      {showLoader && <Loader />}
      {/* <ScrollOff message={modelClick}> */}
      {modelClick && ID.id && (
        <ImageModel
          image={ID.receipt}
          setModelClick={setModelClick}
          modelClick={modelClick}
          message={modelClick}
        />
      )}
      {/* </ScrollOff> */}
      <ToastContainer autoClose={1000} />
      {/* <ScrollOff message={clickReject}> */}{" "}
      {clickReject ? (
        <MasterFormModel
          setClickReject={setClickReject}
          clickReject={clickReject}
          Id={storeId}
          status={status}
          getUserData={getUserData}
          title="deposit rejection by bank"
          message={clickReject}
        />
      ) : (
        ""
      )}
      {/* </ScrollOff> */}
      <TableFilter
        HandleSearchInputChange={HandleSearchInputChange}
        Filter={Filter}
        placeholder={"search by name or accountHolderName..."}
      />
      {pendingData?.length > 0 ? (
        <div className="overflow-x-auto">
          {Filter(pendingData)?.length > 0 ? (
            <Table
              Head={Thead}
              counter={counter}
              setCounter={setCounter}
              data={pendingData}
              paginationEnd={pagination.end}
            >
              {Filter(pendingData)
                .slice(StartValue, EndValue)
                .map((val, i) => {
                  return (
                    <tr
                      key={i}
                      width={700}
                      className="border-b text-center hover:bg-white"
                    >
                      <td className="px-3 py-7 ">{StartValue + 1 + i}</td>
                      <td className="px-3 capitalize font-semibold">
                        {val?.user?.fullName}
                      </td>
                      <td className="px-7 capitalize font-light">
                        {val?.bankName}
                      </td>
                      <td className="px-1  text-sm font-light">
                        {val?.accountNO}
                      </td>
                      <td className="px-1 text-sm font-light capitalize">
                        {val?.accountHolderName}
                      </td>
                      <td className="px-1 text-sm">{val?.amount}</td>
                      <td className="px-1 text-xs">
                        {new Date(val?.createdAt).toDateString()}
                      </td>
                      <td className="px-3" onClick={() => handleImage(val)}>
                        <div className="flex justify-center">
                          {" "}
                          <img
                            src={`${val?.receipt}`}
                            alt="Loading..."
                            width={20}
                            height={20}
                            className="w-14 h-14 rounded-full hover:scale-105 cursor-pointer object-cover overflow-hidden bg-black -900 object-top "
                          />
                        </div>
                        {/* {modelClick && val.id === ID && (
                          <ImageModel
                            image={val.receipt}
                            setModelClick={setModelClick}
                          />
                        )} */}
                      </td>
                      <td className="px-3 text-sm font-light  text-yellow-500 capitalize">
                        {val?.status}
                      </td>

                      <td className="px-3">
                        <div
                          className=" p-2.5 capitalize text-sm flex gap-3 items-center  justify-center
                     border-gray-300 "
                        >
                          <div
                            className="px-3 py-1.5 border w-20 hover:bg-green-600 bg-white hover:text-white  rounded-[3px]  cursor-pointer"
                            onClick={() => Conformation(val)}
                          >
                            accept
                          </div>
                          <div
                            className="px-3 py-1.5 border w-20 hover:bg-red-500 bg-white hover:text-white  rounded-[3px]  cursor-pointer"
                            onClick={() => handleReject(val.id)}
                          >
                            reject
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </Table>
          ) : (
            <div className="text-left px-8 py-3">
              <p className="text-gray-500 font-medium">No data found......</p>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Table Head={Thead}></Table>
          <div className=" flex justify-center items-center h-[400px] capitalize text-gray-600 font-semibold text-xl">
            {!showLoader && `no data available yet`}
          </div>
        </div>
      )}
    </ContextApi>
  );
}

export default DepositByBank;
