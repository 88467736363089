import React, { useState, useEffect } from "react";

import Table from "../../UI/Table";
import MasterDeleteModal from "../../UI/MasterDeleteModal/MasterDeleteModal";
import TableFilter from "../../components/pageComponents/TableFilter/TableFilter";
import ImageModel from "../../UI/ImageModel/ImageModel";
import MasterFormModel from "../../UI/RejectionFormModel/RejectionFormModel";
import ContextApi from "../../HOC/ContextApi/ContextApi";
import axios from "../../HOC/Axios/Axios";
import { MdDelete, MdEdit } from "react-icons/md";
import { BsEyeFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import UserDetailFormModel from "../../components/pageComponents/UserDetailFormModel/UserDetailFormModel";
import { BiCoinStack } from "react-icons/bi";
import UserUpdateAmount from "../../components/pageComponents/UserDetailFormModel/UserUpdateAmount";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../components/pageComponents/Spinner/Spinner";
import Skeleton from "react-loading-skeleton";
function UserDetails() {
  const navigate = useNavigate();
  const [message, setMessage] = useState(false);
  const [modelClick, setModelClick] = useState(false);
  const [filteredData, setFilteredData] = useState("");
  const [counter, setCounter] = useState(1);
  const [showUserForm, setShowUserForm] = useState(false);
  const [deleteID, setDeleteID] = useState("");

  const [showUpdateAmount, setShowUpdateAmount] = useState(false);
  // storing table index only i used ID
  const [ID, setID] = useState(false);
  const [pagination] = useState({
    start: 0,
    end: 10,
  });

  const EndValue = counter * pagination.end;
  const StartValue = EndValue - pagination.end;
  // console.log(StartValue, EndValue);
  const [clickReject, setClickReject] = useState(false);
  // for amount i use state which store user id in userId
  const [userId, setUserId] = useState("");
  // for view user data i use state which store user id in userIds
  const [userIds, setUserIds] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const Thead = [
    { title: "s.nO", with: "10%" },
    { title: "full name", with: "10%" },
    { title: "email", with: "10%" },
    { title: "balance", with: "10%" },
    { title: "country", with: "15%" },
    { title: "phoneNo", with: "10%" },
    { title: "legal document", with: "10%" },
    { title: "credit score", with: "10%" },
    { title: "action", with: "15%" },
  ];

  const [tableData, setTableData] = useState([]);
  const [showAction, setShowAction] = useState(false);
  const HandleSearchInputChange = (e) => {
    // console.log(e.target.value);
    setFilteredData(e.target.value);
  };
  const getUserData = () => {
    setShowLoader(true);
    axios
      .get(`/user`)
      .then((res) => {
        // console.log(res.data.result);
        setTableData(res?.data?.result);
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };
  useEffect(() => {
    getUserData();
  }, []);
  const Filter = (options) => {
    return options.filter((option) => {
      return (
        option["fullName"].toLowerCase().indexOf(filteredData.toLowerCase()) >
          -1 || option["phoneNo"].startsWith(filteredData)
      );
    });
  };
  const Edit = (id) => {
    setShowUserForm(true);
    setUserIds(id);
  };
  let mod;
  mod = (
    <div className=" absolute top-0 left-0 h-full w-full z-50 ">
      <UserDetailFormModel
        getUserData={getUserData}
        setShowUserForm={setShowUserForm}
        userIds={userIds}
      />
    </div>
  );
  const DeleteItems = () => {
    setShowSpinner(true);
    axios
      .delete(`/user/${deleteID}`)
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          toast.error(res.data.message);
          setTimeout(() => {
            setShowSpinner(false);
            setMessage(false);
            getUserData();
          }, 900);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.response.message);
        setShowSpinner(false);
      });
  };
  const Conformation = (id) => {
    setMessage(true);
    setDeleteID(id);
    // console.log(id);
  };
  // console.log(tableData);
  let ConformationMessage;
  if (message) {
    ConformationMessage = (
      <MasterDeleteModal
        Delete={DeleteItems}
        setMessage={setMessage}
        message={message}
      />
    );
  }

  const UpdateAmount = (val) => {
    setShowUpdateAmount(true);
    setUserId(val);
  };
  let updateAmountModel;
  updateAmountModel = (
    <div className=" fixed bg-black flex justify-center items-center  bg-opacity-40 top-0 left-0 h-full w-full z-50">
      <UserUpdateAmount
        getUserData={getUserData}
        storeAcceptID={userId}
        setShowUpdateAmount={setShowUpdateAmount}
        message={showUpdateAmount}
      />
    </div>
  );
  const handleImage = (val) => {
    setModelClick(true);
    setID(val);
  };
  return (
    <ContextApi>
      {modelClick && ID.id && (
        <ImageModel
          image={ID?.validId}
          message={modelClick}
          setModelClick={setModelClick}
        />
      )}
      <ToastContainer autoClose={1000} />
      {showUserForm && mod}
      {showSpinner && <Spinner />}
      {showUpdateAmount && updateAmountModel}
      {message && ConformationMessage}
      {clickReject ? (
        <MasterFormModel
          setClickReject={setClickReject}
          clickReject={clickReject}
          message={clickReject}
        />
      ) : (
        ""
      )}
      <TableFilter
        HandleSearchInputChange={HandleSearchInputChange}
        Filter={Filter}
        placeholder={"search by name or phone number..."}
      />

      {tableData && tableData.length > 0 ? (
        <div className=" overflow-x-auto tablet:overflow-x-clip">
          {Filter(tableData) && Filter(tableData).length > 0 ? (
            <Table
              Head={Thead}
              counter={counter}
              setCounter={setCounter}
              data={tableData}
              paginationEnd={pagination.end}
              title="user details"
              StartValue={StartValue}
              EndValue={EndValue}
            >
              {Filter(tableData)
                .slice(StartValue, EndValue)
                .map((val, i) => {
                  return (
                    <tr
                      key={i}
                      width={val.width}
                      className="border-b text-center hover:bg-white"
                    >
                      <td className="px-3 py-7 ">{StartValue + 1 + i}</td>
                      <td className="px-3 capitalize font-semibold">
                        {val?.fullName}
                      </td>
                      <td className="px-1  text-sm font-light">{val?.email}</td>
                      <td className="px-1 text-sm font-light capitalize">
                        {`$ ${Number(val?.amount?.balance).toFixed(2)}`}
                      </td>
                      <td className="px-1 text-sm capitalize">
                        {val?.country}
                      </td>
                      <td className="px-1 text-xs">{val?.phoneNo}</td>
                      <td className="px-3" onClick={() => handleImage(val)}>
                        <div className="flex justify-center">
                          <img
                            src={`${val.validId}`}
                            alt="Loading..."
                            width={20}
                            height={20}
                            className="w-14 h-14 rounded-full hover:scale-105 cursor-pointer object-cover overflow-hidden bg-black -900 object-top "
                          />
                        </div>
                      </td>
                      <td className="px-3 text-sm font-medium  text-gray-500 capitalize">
                        {val.creditScore ? val.creditScore : 0}
                      </td>

                      <td className="px-3  relative">
                        <div
                          onMouseLeave={() => {
                            setShowAction(false);
                          }}
                          onClick={() => {
                            setShowAction(!showAction);
                            setID(val.id);
                          }}
                          className="cursor-pointer   bg-gray-700  rounded-md hover:scale-105 text-white px-3 py-1.5  font-normal text-sm capitalize"
                        >
                          view
                        </div>
                        {showAction && ID === val.id ? (
                          <div
                            className={`p-2.5 bg-gray-100 rounded-sm absolute -left-[95px]  z-[30] capitalize text-sm 
                        ${
                          i === tableData.length - 1
                            ? " -top-[56px]  "
                            : "top-[54px]"
                        } 
                        gap-3 items-center justify-center border-gray-300`}
                            onMouseLeave={() => {
                              setShowAction(false);
                            }}
                            onMouseEnter={() => setShowAction(true)}
                          >
                            <div
                              className="px-2 py-1.5 border gap-2  font-semibold my-2  hover:bg-secondMainColor  flex  justify-center items-center  bg-white hover:text-white  rounded-[3px]  cursor-pointer"
                              onClick={() => {
                                navigate(`/user_details/${val.id}`, {
                                  query: {
                                    withdraw: "true",
                                    deposit: "true",
                                    trade: "true",
                                  },
                                });
                                setShowAction(false);
                              }}
                            >
                              <div>
                                <BsEyeFill />
                              </div>{" "}
                              <div>view user</div>
                            </div>
                            <div
                              className="px-2 py-1.5 border gap-2  font-semibold my-2  hover:bg-secondMainColor  flex  justify-center items-center  bg-white hover:text-white  rounded-[3px]  cursor-pointer"
                              onClick={() => {
                                Edit(val.id);
                                setShowAction(false);
                              }}
                            >
                              <MdEdit /> edit user
                            </div>
                            <div
                              className="px-2 py-1.5 border gap-2  font-semibold my-2  hover:bg-secondMainColor  flex  justify-center items-center  bg-white hover:text-white  rounded-[3px]  cursor-pointer"
                              onClick={() => Conformation(val.id)}
                            >
                              <MdDelete /> delete user
                            </div>
                            <div
                              className="px-2 py-1.5 border gap-2 w-44  font-semibold my-2  hover:bg-secondMainColor  flex  justify-center items-center  bg-white hover:text-white  rounded-[3px]  cursor-pointer"
                              onClick={() => {
                                UpdateAmount(val);
                                setShowAction(false);
                              }}
                            >
                              <BiCoinStack /> update amount
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  );
                })}
            </Table>
          ) : (
            <div className="text-left px-8 py-3">
              <p className="text-gray-500 font-medium">No data found......</p>
            </div>
          )}
        </div>
      ) : (
        <div className="">
          <Table Head={Thead}></Table>
          <div className="px-8 h-full">
            {showLoader ? <Skeleton className="h-96" /> : null}
          </div>
          <div className=" flex justify-center items-center h-[400px] capitalize text-gray-600 font-semibold text-xl">
            {!showLoader && `no data available yet`}
          </div>
        </div>
      )}
    </ContextApi>
  );
}

export default UserDetails;
