import { NavData } from "../NavData";
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Collapse from "../../pageComponents/Collapse/Collapse";

function Navitems({ setShowCancelButton, showSidebar, show, setShow }) {
  const router = useLocation();
  const [ID, setID] = useState(0);
  const handleNavLinks = (val) => {
    if (val.subTitle) {
      setID((prevID) => (prevID === val.id ? null : val.id)); // Toggle the open state of the clicked subnav
      // document.body.style.overflow = "hidden";
    } else {
      setShow(false);
      setID((prevID) => (prevID === val.id ? val.id : null)); // Toggle the open state of the clicked subnav

      setShowCancelButton(false);

      // document.body.style.overflow = "auto";
    }
  };

  return (
    <div className={`${showSidebar ? "tablet:mt-1" : ""}`}>
      <div className="  ">
        {NavData?.map((val, i) => {
          return (
            <div className="relative" key={i}>
              <NavLink
                to={val.path}
                onClick={() => handleNavLinks(val)}
                className={` ${
                  router.pathname === val.path
                    ? "  bg-mainColor text-secondMainColor "
                    : " hover:bg-mainColor hover:text-secondMainColor   "
                }
          border
          ${val.subTitle?.map((vals) => {
            return vals.paths === router.pathname
              ? "  bg-mainColor text-secondMainColor  "
              : null;
          })}
         tablet:mb-3.5 px-1 my-2 font-semibold   cursor-pointer rounded-md  capitalize
         flex mx-3 gap-2  relative items-center py-2
          `}
              >
                <div> {val.icon}</div>
                <div className="flex justify-between w-full">
                  <div
                    className={`${showSidebar ? "block tablet:hidden" : ""}`}
                  >
                    {val.title}
                  </div>
                  {ID === val.id ? (
                    <div
                      className={`${
                        showSidebar ? "hidden" : "block"
                      } text-2xl px-8`}
                    >
                      {val.icon1}
                    </div>
                  ) : (
                    <div
                      className={` ${
                        showSidebar ? "hidden" : "block"
                      } text-2xl px-8`}
                    >
                      {val.icon2}
                    </div>
                  )}
                </div>
              </NavLink>

              <Collapse
                showSidebar={showSidebar}
                setShowCancelButton={setShowCancelButton}
                isOpen={val.id === ID}
                title={val.title}
                setShow={setShow}
              >
                {val.subTitle?.map((sub, index) => {
                  return (
                    <NavLink
                      to={sub.paths}
                      key={index}
                      onClick={() => setID("")}
                    >
                      <div
                        className={` ${
                          router.pathname === sub.paths
                            ? "bg-[white]  text-secondMainColor "
                            : " hover:bg-[white] hover:text-secondMainColor text-black  "
                        }
             w-[95%] capitalize  mx-auto px-2 h-fit   tablet:my-4 my-2 font-semibold 
               cursor-pointer rounded-[3px] flex items-center gap-2   py-2
            
            
    
                 `}
                      >
                        <div
                          className={` ${showSidebar ? "w-fit mx-auto" : ""}`}
                        >
                          {" "}
                          {sub.icon}
                        </div>
                        <div
                          className={` ${
                            showSidebar ? "hidden" : show ? "block" : "block"
                          } `}
                        >
                          {sub.sub_title}
                        </div>
                      </div>
                    </NavLink>
                  );
                })}
              </Collapse>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Navitems;
