import React from "react";
import { useLocation } from "react-router-dom";

function BredCrums() {
  const location = useLocation();
  //   console.log(location.pathname.split("/"));
  return (
    <div>
      {location.pathname === "/"
        ? "dashboard"
        : location.pathname.split("/")[1].split("_").join(" ")}
    </div>
  );
}

export default BredCrums;
